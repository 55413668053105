import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAppSelector, useAppDispatch} from 'hooks';
import {
  WizardPageStateProps,
  wizardPageState,
  WIZARD_INIT,
  WIZARD_TO_STUB,
  WIZARD_PAGE_ROUTE_THUNK,
  WIZARD_STATE_UPDATE,
  Loading,
} from 'features';
import {
  PaymentDetailsStep,
  BagSelectorComponent,
  PersonalDetailsStep,
  DeliveryDetailsStep,
} from './steps';

/**
 * Be careful updating /protected: Make sure to sync changes
 * to Azure B2C Html + User Flows!!
 */
type FormStepStubTypes =
  | 'create-bag'
  | 'delivery-details'
  | 'personal-details'
  | 'payment'
  | 'choose-recipes';

export interface OrderFormStepManagerProps {
  stepStub?: FormStepStubTypes;
}

const initializeOrderFormSteps = () => {
  const orderFormSteps: Array<WizardPageStateProps<FormStepStubTypes>> = [
    {
      pageName: 'Create Bag',
      pageStub: 'create-bag',
      pageState: 'IDLE',
    },
    {
      pageName: 'Personal Details',
      pageStub: 'personal-details',
      pageState: 'IDLE',
    },
    {
      pageName: 'Delivery Details',
      pageStub: 'delivery-details',
      pageState: 'IDLE',
    },
    {
      pageName: 'Payment Details',
      pageStub: 'payment',
      pageState: 'IDLE',
    },
    {
      pageName: 'Choose Recipes',
      pageStub: 'choose-recipes',
      pageState: 'IDLE',
    },
  ];

  return orderFormSteps;
};

const OrderFormStepManager: React.FunctionComponent<
  OrderFormStepManagerProps
> = ({stepStub}) => {
  const {wizardState, currentPageNumber, pages, ...state} =
    useAppSelector(wizardPageState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentPage =
    (state.currentPage as WizardPageStateProps<FormStepStubTypes>);

  const isLastPage = currentPageNumber === pages.length - 1;

  /**
   * Form Manager Step Initializer
   */
  React.useEffect(() => {
    if (wizardState === 'OFF') {
      const orderFormSteps = initializeOrderFormSteps();
      dispatch(WIZARD_INIT({pages: orderFormSteps, pageStub: stepStub}));
    }
  }, [dispatch, stepStub, wizardState]);

  /**
   * Form Manager / Router URL Sync
   */
  React.useEffect(() => {
    if (stepStub) {
      dispatch(WIZARD_TO_STUB({pageStub: stepStub}));
    }
  }, [dispatch, stepStub]);

  /**
   * Respond to WizardState 'Continue' state changes i.e. @see OrderForm:OnContinue
   */
  React.useEffect(() => {
    if (currentPage?.pageState === 'SUCCESS' && wizardState === 'CONTINUE') {
      dispatch(
        WIZARD_PAGE_ROUTE_THUNK({
          routeType: {direction: 'next'},
          navigate: navigate,
        })
      );
    } else if (currentPage?.pageState === 'IDLE' && wizardState === 'CONTINUE') {
      dispatch(WIZARD_STATE_UPDATE({wizardState: 'IDLE'}));
    }
  }, [currentPage?.pageState, dispatch, isLastPage, navigate, wizardState]);

  /**
   * Place all your StepComponent definitions in here under the appropriate case blocks.
   * @returns The step component to render correlating to the requested path.
   */
  switch (wizardState) {
    case 'CONTINUE':
    case 'IDLE':
      switch (currentPage.pageStub) {
        case 'create-bag':
          return <BagSelectorComponent />;
        case 'personal-details':
          return <PersonalDetailsStep pageState={currentPage.pageState} />;
        case 'delivery-details':
          return <DeliveryDetailsStep pageState={currentPage.pageState} />;
        case 'payment':
          return <PaymentDetailsStep pageState={currentPage.pageState} />;
        case 'choose-recipes':
          return <Navigate replace to="/success" />;
        default:
          return <Navigate to="/404" />;
      }
    default:
      return <Loading />;
  }
};

export {OrderFormStepManager};
