import React from 'react';
import {MsalProvider, useMsal} from '@azure/msal-react';
import {loginRequest, msalSignInInstance} from 'config';
import {useAppSelector} from 'hooks';
import {wizardFormPersonalDetails, Loading} from 'features';

export interface LoginProps extends React.PropsWithChildren {}

const LoginRedirect = () => {
  const {instance} = useMsal();
  const wizardState = useAppSelector(wizardFormPersonalDetails);

  const request = {...loginRequest};
  request.loginHint = wizardState.emailAddress;

  React.useEffect(() => {
    if (instance) {
      instance.loginRedirect(request).catch((e) => {
        console.error(e);
      });
    }
  });
  return <Loading />;
};

export const Login = () => {
  return (
    <MsalProvider instance={msalSignInInstance}>
      <LoginRedirect />
    </MsalProvider>
  );
};

export default Login;
