import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {rootReducer} from 'app';
import {persistStore, persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import * as Sentry from '@sentry/react';
import {orderformApi} from 'features';

/**
 * redux-persist config
 *
 * https://github.com/rt2zz/redux-persist/blob/master/docs/api.md#type-persistconfig
 */
const persistConfig = {
  key: 'ORDER_FORM_DATA',
  storage: storageSession,
  // Ignore these redux state props
  blacklist: [
    // wizardPageSliceName,
    orderformApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: persistedReducer,
  enhancers: [sentryReduxEnhancer],
  middleware: (gDM) =>
    gDM({
      serializableCheck: false,
    }).concat(orderformApi.middleware),
});

setupListeners(store.dispatch);

/**
 * The persistor object is returned by persistStore with the following methods:
 *
 * `.purge()`
 * purges state from disk and returns a promise
 *
 * `.flush()`
 * immediately writes all pending state to disk and returns a promise
 *
 * `.pause()`
 * pauses persistence
 *
 * `.persist()`
 * resumes persistence
 *  */
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
