import React from 'react';
import {DeepRequired, FieldErrorsImpl, FieldValues} from 'react-hook-form';

export type ErrorMessageCallback = (fieldName: string) => string | undefined;

export const useErrorMessage = (
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>
) => {
  return React.useCallback<ErrorMessageCallback>(
    (fieldName: string): string | undefined => {
      const error = errors[fieldName];

      return error && error.message ? String(error.message) : undefined;
    },
    [errors]
  );
};
