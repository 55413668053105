import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const StyledHomeButton = styled(Link)``;

export const NotFound = () => (
  <div className="container-sm py-5">
    <h1>Page not found!</h1>
    <p>
      {`We're sorry, the page doesn't exist or may no longer be available. Please
      check the URL for correct spelling.`}
    </p>
    <p>{`Alternatively, try restarting your order by clicking the button below.`}</p>
    <StyledHomeButton className="btn btn-primary" to="/">
      Restart Order
    </StyledHomeButton>
  </div>
);
