import React from 'react';
import {StickyHeader, StickyHeaderProps} from '@mfb/cookbook';
import {Colours} from '@mfb/lego';
import styled from 'styled-components';

const StyledStickyHeader = styled(StickyHeader)`
  padding: ${({theme}) => `0 ${theme.spacing.small}`};
  border-bottom: 1px solid ${Colours.SECONDARY_DARK_CREAM};
`;

const OrderFormHeader: React.FC<StickyHeaderProps> = (props) => (
  <StyledStickyHeader {...props} />
);

export {OrderFormHeader};
