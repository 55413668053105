import config from './config.json';


export interface Configuration {
  backendApiUrl: string;
  accountUrl: string;
  analytics: {
    gtm: string;
    vwoAccountId: string;
    sentryDSN: string;
    pageNames: {
      bagSelector: string;
      personalDetails: string;
      deliveryDetails: string;
      paymentDetails: string;
      authRedirect: string;
      paymentRedirect: string;
      errorPage: string;
    };
    pageNumbers: {
      errorPage: number;
      authRedirect: number;
      paymentRedirect: number;
      paymentFailure: number;
      success: number;
    };
  };
  images: {
    welcomeBag: string;
  };
  b2c: {
    tenantName: string;
    clientId: string;
    redirectUrn: string;
    scopes: {
      accountApiUrn: string;
    };
    flows: {
      signUp: string;
      signIn: string;
    };
  };
}

export let appConfig: Configuration;
appConfig = {
  ...config,
  analytics: {
    ...(config.analytics || {}),
    pageNames: {
      bagSelector: 'SKU Selector',
      personalDetails: 'User Details',
      deliveryDetails: 'Delivery Details',
      paymentDetails: 'Payment Details',
      authRedirect: 'Auth Redirect',
      paymentRedirect: 'Credit Card',
      errorPage: 'Error',
    },
    pageNumbers: {
      authRedirect: 5,
      paymentRedirect: 6,
      success: 7,
      errorPage: 500,
      paymentFailure: 501,
    },
    gtm: '',
    vwoAccountId: '',
    sentryDSN: '',
  },
};
