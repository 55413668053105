import {FlexPanel, ThemeUtils} from '@mfb/lego';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

export const StyledSpinner = styled.div<LoadingProps>`
  width: ${(props) =>
    ThemeUtils.ScaleValue(props.$size ?? props.theme.spacing.xxlarge, 3)};
  height: ${(props) =>
    ThemeUtils.ScaleValue(props.$size ?? props.theme.spacing.xxlarge, 3)};
  color: ${(props) => props.theme.colors.primary};
`;

export const LoadingWrapper = styled(FlexPanel)`
  height: 100%;
`;

interface LoadingProps {
  className?: string;
  $size?: string;
}

export const Loading: React.FunctionComponent<LoadingProps> = ({
  className,
  ...props
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // redirect to scrumbled scrambled eggs after 5 mins if user stuck on loading for some reason..
  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300000);
  }, []);

  React.useEffect(() => {
    if (isLoading === false) {
      navigate('/error', {
        state: {
          id: 'Loading Spinner Screen',
          error: new Error('Loading spinner timeout'),
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const classes = classNames(
    'justify-content-center align-items-center',
    className
  );
  return (
    <LoadingWrapper className={classes}>
      <StyledSpinner className="spinner-border" role="status" {...props}>
        <span className="sr-only">Loading...</span>
      </StyledSpinner>
    </LoadingWrapper>
  );
};
