import {RecipeModel} from '@mfb/cookbook';
import {useRecipeCollectionsQuery} from 'features';
import {useRecipeDate} from './useRecipeDate';

interface RecipeCollectionData {
  recipes: RecipeModel[];
  id: string;
  day: string;
}

export const useRecipes = (sku: string, week?: Date) => {
  const {recipeWeekIso, recipeWeek} = useRecipeDate(week);

  const {
    data: staleData,
    currentData,
    isFetching: isRecipesFetching,
    isSuccess: isRecipesSuccess,
    requestId: recipesRequestId,
  } = useRecipeCollectionsQuery(
    {sku: sku ?? '', weekDateIsoString: recipeWeekIso},
    {skip: !sku || !recipeWeekIso}
  );

  const recipeData: RecipeCollectionData = {
    recipes: [],
    id: '',
    day: recipeWeek.toLocaleDateString(),
  };

  const recipeDataWrapper: Array<RecipeCollectionData> = [];

  // Notice lag in image changes? it's this. Remove stale data to have flashing carousels.
  const recipesCollection = currentData ?? staleData;
  let collectionKey: string = '';

  if (isRecipesSuccess && recipesCollection) {
    recipeData.recipes = recipesCollection
      // right now the response won't output the unitSurcharge field if it's null
      .filter((item) => !item.unitSurcharge)
      .map((item, index) => {
        return {
          src: item.imageUrl,
          name: item.title!,
          id: `${item.recipeNumber}_${item.recipeVersion}_${item.recipePartition}`,
          rnumber: item.recipeNumber,
          rversion: item.recipeVersion,
          partition: item.recipePartition,
          rid: index,
        } as RecipeModel;
      });

    recipeData.id = sku;
    recipeDataWrapper.push(recipeData);

    collectionKey =
      recipeData.id +
      recipeData.day +
      recipeData.recipes.map((recipe) => recipe.id).join('');
  }

  return {
    recipesCollection: recipeDataWrapper,
    recipeCollectionId: collectionKey,
    isRecipesFetching,
    isRecipesSuccess,
    recipesRequestId,
  };
};
