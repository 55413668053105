import React, {useState, useEffect} from 'react';
import {
  useAppDispatch,
  useAppSelector,
  useRecipes,
  useProducts,
  useSkuFromUrlEffect,
  useRecipeDetails,
} from 'hooks';
import {
  BagSelector,
  BagSelectorProductResult,
  BagSelectorStateService,
  RecipeCarouselContext,
  SkuSelectorContext,
} from '@mfb/cookbook';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {
  trackEcommerceAnalyticsThunk,
  useCreateCartMutation,
  Loading,
  WIZARD_FORM_UPDATE,
  wizardFormProductInfo,
  WIZARD_CURRENTPAGE_VALIDATE_RESULT,
  wizardPageState,
} from 'features';
import {appConfig} from 'config';
import {RecipeCarouselItemVariants} from '@mfb/lego';

const StyledBagSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BagSelectorComponent: React.FunctionComponent<any> = () => {
  const dispatch = useAppDispatch();
  const [pageInit, setPageInit] = useState(true);

  const [carouselContext, setCarouselContext] = useState<RecipeCarouselContext>(
    {
      recipeCollections: [],
      showDateSelector: false,
      disableDetails: false,
      carouselItemVariant: RecipeCarouselItemVariants.RecipeCard,
    }
  );
  const [skuSelectorContext, setSkuSelectorContext] =
    useState<SkuSelectorContext>({
      skuSelectorService: new BagSelectorStateService([]),
      productsLabel: '',
      nightsLabel: 'How Many Nights?',
      servingsLabel: 'Number of People?',
    });

  const navigate = useNavigate();

  // wizard state
  const wizardState = useAppSelector(wizardPageState);
  const {productSku} = useAppSelector(wizardFormProductInfo);

  // Query Param SKUs
  useSkuFromUrlEffect();

  // OrderForm API Calls
  const {isSuccess, isFetching, productsCollection, productGroups} =
    useProducts();
  const {recipesCollection, recipeCollectionId} = useRecipes(productSku);
  const [createCart] = useCreateCartMutation();
  const {requestRecipeDetails} = useRecipeDetails();

  const isLoading =
    pageInit ||
    !skuSelectorContext.skuSelectorService.ProductGroupCollection.length ||
    isFetching;

  /**
   * Effect that tracks the component pageInit
   */
  React.useEffect(() => {
    (async () => {
      if (pageInit) {
        await dispatch(
          trackEcommerceAnalyticsThunk({
            pageName: appConfig.analytics.pageNames.bagSelector,
          })
        );
        setPageInit(false);
      }
    })();
  }, [dispatch, pageInit]);

  /**
   * REQUIRED EFFECT
   * Respond to external validation requests i.e. from @see OrderForm
   */
  useEffect(() => {
    (async () => {
      try {
        const skuList = productsCollection.flatMap((item) => item.itemNumber);
        if (wizardState.wizardState === 'CONTINUE' && productSku) {
          const skuCheck = Object.values(skuList).some((sku) =>
            sku.includes(productSku)
          );
          if (skuCheck) {
            const {cartId} = await createCart({sku: productSku}).unwrap();
            dispatch(WIZARD_FORM_UPDATE({cartId: cartId}));
            dispatch(WIZARD_CURRENTPAGE_VALIDATE_RESULT({isValid: true}));
          }
        }
      } catch (error) {
        navigate('/error', {state: {id: 'create-bag', error: error}});
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardState.wizardState]);

  /**
   * Update Sku Selector when productSku updated.
   */
  useEffect(() => {
    if (isSuccess) {
      setSkuSelectorContext({
        ...skuSelectorContext,
        skuSelectorService: new BagSelectorStateService(productGroups),
        skuOverride: productSku || undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSku, isSuccess]);

  /**
   * Update Recipe Carousel when new data arrives.
   */
  useEffect(() => {
    setCarouselContext({
      ...carouselContext,
      recipeCollections: recipesCollection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeCollectionId]);

  const onResult = React.useCallback(
    async (newValue: BagSelectorProductResult | undefined) => {
      try {
        const selectedProduct = productsCollection.find(
          (_product) => _product.itemNumber === newValue?.itemNumber
        );

        if (!selectedProduct) {
          return;
        }

        try {
          await dispatch(
            WIZARD_FORM_UPDATE({
              sku: selectedProduct.itemNumber,
              nights: selectedProduct.nights,
              productName: selectedProduct.name,
            })
          );
        } catch (error) {
          navigate('/error', {state: {id: 'create-bag', error: error}});
        }
      } catch (err) {
        console.error(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  );

  const recipeDetailDisplay = async (
    recipeNumber?: string,
    recipeVersion?: string,
    partition?: string,
    recipeId?: number
  ) => {
    const recipeDetailsResult = await requestRecipeDetails(
      partition ?? '',
      recipeNumber ?? '',
      recipeVersion ?? ''
    );

    return recipeDetailsResult;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <StyledBagSelectorContainer>
          <div style={{width: '100%'}}>
            <BagSelector
              title="Let's get cooking!"
              recipeCarouselContext={carouselContext}
              productPriceDisplayContext={{primaryButtonHidden: true}}
              skuSelectorContext={skuSelectorContext}
              skuSelectorOnResult={onResult}
              recipeCarouselOnRecipeFetch={recipeDetailDisplay}
            />
          </div>
        </StyledBagSelectorContainer>
      )}
    </>
  );
};
