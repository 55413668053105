import {Client} from 'app';
import {appConfig} from 'config';
import {
  getEmptyTokenConfig,
  getTokenConfig,
  Options as RetryOptions,
  retryRequest,
} from 'utilities';

export enum ApiClientErrorCodes {
  CustomerMismatchErrorCode = 'ERR00001',
}

export const ApiClient = (bearerToken: string = '') => {
  let config = bearerToken
    ? getTokenConfig(bearerToken)
    : getEmptyTokenConfig();
  return new Client(config, appConfig.backendApiUrl);
};

/**
 * Retries promises based on exceptions thrown. Backs off exponentially.
 *
 * @example
 * // Retries can be skipped by throwing the following in `promiseToRetry`
 * throw new AbortError(response.statusText);
 * @param promiseToRetry promise to retry
 * @param options retry options
 * @returns
 */
export function retryable<T>(
  promiseToRetry: () => Promise<T>,
  options?: RetryOptions
) {
  // Math.min(random * minTimeout * Math.pow(factor, attempt), maxTimeout)
  // 1, 2, 4, 8 seconds...
  const defaultOptions: RetryOptions = {
    factor: 2,
    minTimeout: 1000,
    retries: 3,
    onFailedAttempt: (error) => {
      console.warn(
        `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`
      );
    },
  };

  return retryRequest(promiseToRetry, {
    ...defaultOptions,
    ...(options ?? {}),
  });
}
