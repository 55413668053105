import React from 'react';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  SilentRequest,
} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {loginRequest} from 'config';
import {useNavigate} from 'react-router-dom';

interface AuthToken {
  isLoading: boolean;
  isAuthenticated: boolean;
  authResult: AuthenticationResult | undefined;
  bearerToken: string | undefined;
  error: Error | undefined;
}

interface UseAuthTokenProps {
  handleErrors?: boolean;
}

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  authResult: undefined,
  bearerToken: undefined,
  error: undefined,
};

/**
 * Use this for acquiring up to date auth information about the current user context.
 * @returns AuthToken otherwise undefined. Undefined only occurs during a redirect.
 */
export const useAuthToken = (props?: UseAuthTokenProps) => {
  props = props ?? {handleErrors: true};

  const {instance, accounts} = useMsal();
  const [authToken, setAuthToken] = React.useState<AuthToken>(initialState);
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        const [user] = accounts;

        if (!user) {
          throw new Error('User is not signed in');
        }

        const request: SilentRequest = {
          ...loginRequest,
          account: user,
        };

        try {
          const authResult = await instance.acquireTokenSilent(request);

          setAuthToken({
            isLoading: false,
            isAuthenticated: true,
            authResult: authResult,
            bearerToken: `Bearer ${authResult.accessToken}`,
            error: undefined,
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            // This is going to trigger a redirect away from the app
            await instance.acquireTokenRedirect(request);
          } else if (error instanceof Error) {
            setAuthToken({
              ...initialState,
              isLoading: false,
              error: error,
            });
          } else {
            throw error;
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setAuthToken({
            ...initialState,
            isLoading: false,
            error: error,
          });
        } else {
          throw error;
        }
      }
    })();
  }, [accounts, instance]);

  React.useEffect(() => {
    if (props?.handleErrors && authToken.error) {
      setAuthToken(initialState);
      navigate('/error', {state: {id: 'auth', error: authToken.error}});
    }
  }, [authToken.error, navigate, props.handleErrors]);

  return authToken;
};
