import {
  AnyAction,
  AsyncThunkAction,
  ThunkDispatch,
  unwrapResult,
} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {RootState, AppDispatch} from 'app';

/**
 * Default dispatcher.
 * @returns The state value returned from the action
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type UseAppDispatchType = ReturnType<typeof useAppDispatch>;

/**
 * Use this dispatcher to unwrap the value from createAsyncThunk actions.
 *
 * This will throw if a rejected value was supplied to the thunk.
 * @returns The unwrapped value returned from the thunk action
 */
export const useThunkDispatch = function thunkDispatchCreator<
  State,
  ExtraThunkArg,
  BasicAction extends AnyAction
>(dispatch: ThunkDispatch<State, ExtraThunkArg, BasicAction>) {
  return async function thunkUnwrapper<Returned, ThunkArg>(
    thunk: AsyncThunkAction<Returned, ThunkArg, {}>
  ) {
    const result = await dispatch(thunk);
    return unwrapResult(result);
  };
};
export type UseThunkDispatchType = ReturnType<typeof useThunkDispatch>;

/**
 * State selector for usage in all components.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
