import {AppStoreButton, PlayStoreButton, ResponsiveImage} from '@mfb/lego';
import * as React from 'react';
import {useAppSelector, useAppDispatch} from 'hooks';
import {
  wizardFormData,
  wizardPageState,
  OrderFormHeader,
  trackEcommerceSuccessAnalyticsThunk,
  paymentDetailsState,
  Loading,
  beginSession,
} from 'features';
import {appConfig} from 'config';
import styled from 'styled-components';
import {SessionBeginRequest} from 'app';

const DisabledWrapper = styled.div`
  pointer-events: none;
`;

const ImageFrame = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  padding-bottom: ${(props) => props.theme.spacing.medium};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledDivTop = styled.div`
  padding: ${(props) => props.theme.spacing.large};
`;

const SpacedContainer = styled.div`
  padding-top: ${(props) => props.theme.spacing.large};
  padding-bottom: ${(props) => props.theme.spacing.large};
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

export const Success: React.FunctionComponent = () => {
  const pageData = useAppSelector(wizardFormData);
  const paymentData = useAppSelector(paymentDetailsState);
  const dispatch = useAppDispatch();
  const {pages} = useAppSelector(wizardPageState);

  const [loadingFinished, setLoadingFinished] = React.useState<boolean>(false);
  const [customerCreated, setCustomerCreated] = React.useState<boolean>(false);

  const MAX_ATTEMPTS = 30;
  const TIME_BETWEEN_ATTEMPTS_MS = 1000;

  React.useEffect(() => {
    const waitForCustomerCreation = async (numAttempts: number) => {
      const beginSessionRequest = new SessionBeginRequest({
        email: pageData.personalDetailsEmailAddress ?? '',
        sku: pageData.sku ?? '',
      });
      const beginSessionResponse = await beginSession(beginSessionRequest);
      if (beginSessionResponse.isCustomer) {
        setLoadingFinished(true);
        setCustomerCreated(true);
      } else if (numAttempts >= MAX_ATTEMPTS) {
        setLoadingFinished(true);
      } else {
        setTimeout(
          waitForCustomerCreation,
          TIME_BETWEEN_ATTEMPTS_MS,
          numAttempts + 1
        );
      }
    };
    waitForCustomerCreation(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (paymentData) {
      dispatch(
        trackEcommerceSuccessAnalyticsThunk({
          transactionId: paymentData.subscriptionNumber ?? 'Unknown',
          affiliation: 'MyFoodBag',
        })
      );
    }
  }, [dispatch, paymentData]);

  return (
    <>
      <DisabledWrapper>
        <OrderFormHeader
          isSticky={false}
          breadcrumbsHaveProgressedTo={pages.length - 2}
          breadcrumbsSelected={pages.length}
          breadcrumbsCheckpoints={[...pages].map((page) => ({
            label: page.pageName,
            disabled: false,
          }))}
          onCheckpointClick={() => null}
        />
      </DisabledWrapper>

      <StyledContainer>
        <StyledDivTop>
          <ImageFrame>
            <ResponsiveImage src={appConfig.images.welcomeBag} />
          </ImageFrame>
          <SpacedContainer>
            <h1>{`Thanks ${pageData.personalDetailsFirstName}!`}</h1>
          </SpacedContainer>
          <p>
            {`You're all set to receive your weekly ${pageData.productName}, for first delivery ${pageData.deliveryDateDescription}.`}
          </p>
          <SpacedContainer>
            {loadingFinished ? (
              customerCreated ? (
                <a
                  href={appConfig.accountUrl}
                  className="btn btn-primary text-white rounded-lg"
                >
                  {`Choose Recipes`}
                </a>
              ) : (
                <BoldSpan>
                  {
                    'Sorry, your account is taking a little while to set up! Please check back with us shortly.'
                  }
                </BoldSpan>
              )
            ) : (
              <div>
                <Loading $size="12px" />
                <BoldSpan>
                  {'Sit tight, we’re whipping up your account!'}
                </BoldSpan>
              </div>
            )}
          </SpacedContainer>
          <p>{`Our cut-off to review recipes and make adjustments to your deliveries is Sunday, 11:59pm.`}</p>
          <p>
            <span>{`Please visit our `}</span>
            <a
              href={'https://help.myfoodbag.co.nz'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`help centre`}
            </a>
            <span>{` for assistance with any issues.`}</span>
          </p>
        </StyledDivTop>
        <SpacedContainer>
          <small>
            {`Don't forget to download our App to stay deliciously sorted!`}
          </small>
          <p>
            <AppStoreButton className="ml-3" />
            <PlayStoreButton />
          </p>
        </SpacedContainer>
      </StyledContainer>
    </>
  );
};
