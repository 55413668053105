import classNames from 'classnames';
import React from 'react';
import {StyledSpinner, LoadingWrapper} from './loading';

export const PersistLoading: React.FunctionComponent = () => {
  const classes = classNames('justify-content-center align-items-center');
  return (
    <LoadingWrapper className={classes}>
      <StyledSpinner className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </StyledSpinner>
    </LoadingWrapper>
  );
};
