import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

export const useQueryParamSync = (param: string, deleteParam = true) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryValue = searchParams.get(param);
  const [state] = useState(queryValue);

  useEffect(() => {
    if (deleteParam && searchParams.has(param)) {
      searchParams.delete(param);
      setSearchParams(searchParams, {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteParam, param, state]);

  return state;
};
