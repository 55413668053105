import React from 'react';
import {FailureScreen, FailureScreenErrors} from '@mfb/cookbook';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAppSelector} from 'hooks';
import {wizardPageState, OrderFormHeader} from 'features';
import styled from 'styled-components';

const DisabledWrapper = styled.div`
  pointer-events: none;
`;

export const Failure: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const {pages} = useAppSelector(wizardPageState);
  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get('code');

  let errorType: FailureScreenErrors;
  switch (errorCode) {
    case '10001': {
      errorType = FailureScreenErrors.CardFailure;
      break;
    }
    case '10004': {
      errorType = FailureScreenErrors.TimeoutFailure;
      break;
    }
    default: {
      errorType = FailureScreenErrors.GenericFailure;
      break;
    }
  }

  return (
    <>
      <DisabledWrapper>
        <OrderFormHeader
          isSticky={false}
          breadcrumbsHaveProgressedTo={pages.length - 2}
          breadcrumbsSelected={pages.length - 1}
          breadcrumbsCheckpoints={pages.map((page) => ({
            label: page.pageName,
            disabled: false,
          }))}
          onCheckpointClick={() => null}
        />
      </DisabledWrapper>
      <FailureScreen
        failureReason={errorType}
        tryAgainCallback={() => navigate('/process')}
      />
    </>
  );
};
