import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app';
import {
  createSubscription,
  WizardFormState,
  ApiClientErrorCodes,
} from 'features';
import {PURGE} from 'redux-persist';
import {CreatePurchaseRequest, SubscriptionDetailRequest} from 'app';
import {ErrorResponse} from 'app';

export const enum DiscountType {
  RaF = 1,
  Promo = 2,
}

const sliceName = 'paymentDetailsPage';
export interface PaymentDetailsSlice {
  redirectUri: string | undefined;
  subscriptionNumber: string | undefined;
  logoutRequired: boolean;
  error: string | undefined;
}

const initialState: PaymentDetailsSlice = {
  redirectUri: undefined,
  subscriptionNumber: undefined,
  logoutRequired: false,
  error: undefined,
};

const generateRequestBody = (wizardFormState: WizardFormState) => {
  let subscriptionDetailRequest = new SubscriptionDetailRequest();

  if (
    wizardFormState.orderSummaryDiscount?.discountType === DiscountType.Promo
  ) {
    subscriptionDetailRequest.discountCode =
      wizardFormState.orderSummaryDiscount?.code;
  }
  subscriptionDetailRequest.cartId = wizardFormState.cartId;
  subscriptionDetailRequest.frequency = 1;
  subscriptionDetailRequest.deliveryDateId = wizardFormState.deliveryDateId!;
  subscriptionDetailRequest.isOneOffBufferSale = false;
  subscriptionDetailRequest.shouldUseDefaultCreditCard = false;
  subscriptionDetailRequest.overrideDeliverySlotId =
    wizardFormState?.overrideDeliverySlotId;

  let request = new CreatePurchaseRequest();
  if (!wizardFormState.customerNumber) {
    throw new Error('Customer Number is required');
  }
  request.customerNumber = wizardFormState.customerNumber;
  request.subscription = subscriptionDetailRequest;
  request.voucherId = wizardFormState.orderSummaryVoucher?.voucherId;

  //If it's not a promo, this won't be added.
  request.promoGroupId = wizardFormState.orderSummaryDiscount?.promoGroupId;

  if (wizardFormState.orderSummaryDiscount?.discountType === DiscountType.RaF) {
    request.referralCode = wizardFormState.orderSummaryDiscount?.code;
  }

  return request;
};

export const getPurchaseUriThunk = createAsyncThunk<
  PaymentDetailsSlice,
  string
>(`${sliceName}/GET_REDIRECT_URI`, async (bearerToken: string, {getState}) => {
  const {wizardForm} = getState() as RootState;
  try {
    const response = await createSubscription(
      wizardForm.sku!,
      generateRequestBody(wizardForm),
      bearerToken
    );

    return {
      ...initialState,
      redirectUri: response.redirectUri,
      subscriptionNumber: response.subscriptionNumber,
    };
  } catch (error) {
    if (error instanceof ErrorResponse) {
      if (error.code === ApiClientErrorCodes.CustomerMismatchErrorCode) {
        console.warn('CustomerMismatch :: Logging Out');
        return {
          ...initialState,
          logoutRequired: true,
        };
      }
    }
    console.log(error);
    throw error;
  }
});

export const paymentDetailsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setRedirectUri: (state, action: PayloadAction<string | undefined>) => {
      state.redirectUri = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseUriThunk.fulfilled, (state, action) => {
      state.error = undefined;
      state.redirectUri = action.payload.redirectUri;
      state.subscriptionNumber = action.payload.subscriptionNumber;
      state.logoutRequired = action.payload.logoutRequired;
    });
    builder.addCase(getPurchaseUriThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? 'unknown';
      state.redirectUri = undefined;
      state.subscriptionNumber = undefined;
      state.logoutRequired = false;
    });
    builder.addCase(PURGE, () => void initialState);
  },
});

export const {setRedirectUri} = paymentDetailsSlice.actions;

export const paymentDetailsState = (state: RootState) => state.paymentDetails;

export const paymentDetailsReducer = paymentDetailsSlice.reducer;
