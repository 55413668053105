import {useMsal} from '@azure/msal-react';
import React from 'react';
import {InteractionStatus} from '@azure/msal-browser';
import {GuardedRoute} from 'routes';
import {PaymentRedirect, Loading} from 'features';

export const Session: React.FunctionComponent = () => {
  const {instance, inProgress} = useMsal();
  const [requestPayment, setRequestPayment] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (inProgress === InteractionStatus.None) {
        try {
          // to resolve before calling any interactive API
          await instance.handleRedirectPromise();
        } catch (e) {
          // exception swallowed to prevent B2C
          // from errorring on redirect
        }

        // Check if any user(s) signed in
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          // logout
          await instance.logoutRedirect({
            onRedirectNavigate: () => false, // local logout
          });
        }

        setRequestPayment(true);
      }
    })();
  }, [inProgress, instance]);

  if (requestPayment) {
    return (
      <GuardedRoute>
        <PaymentRedirect replace={false} />
      </GuardedRoute>
    );
  }

  return <Loading />;
};
