import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app';
import {paramCase} from 'change-case';
import {NavigateFunction} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {PURGE} from 'redux-persist';

// CONSTANTS
export const wizardPageSliceName = 'wizardPage';
const calculateThunkState = (getState: () => unknown) =>
  (getState() as {[wizardPageSliceName]: WizardStateProps})[
    wizardPageSliceName
  ] ?? {};

// THUNKS
export type WizardPageRouteThunkTypes =
  | {direction: 'next'}
  | {direction: 'back'}
  | {direction: 'page'; pageNumber: number};

/**
 * This thunk executes the necessary page actions, and then navigates
 * the browser to the next page state.
 *
 * We do this to sync state with the browser history.
 */
export const WIZARD_PAGE_ROUTE_THUNK = createAsyncThunk(
  `${wizardPageSliceName}/WIZARD_NEXT_PAGE_ROUTE_THUNK`,
  async (
    {
      routeType,
      navigate,
    }: {routeType: WizardPageRouteThunkTypes; navigate?: NavigateFunction},
    {getState}
  ) => {
    let state = calculateThunkState(getState) as WizardStateProps;
    let compare = cloneDeep(state);

    switch (routeType.direction) {
      case 'next':
        state = wizardPageReducer(state, WIZARD_NEXT_PAGE());
        break;
      case 'back':
        state = wizardPageReducer(state, WIZARD_PREVIOUS_PAGE());
        break;
      case 'page':
        state = wizardPageReducer(
          state,
          WIZARD_TO_PAGE({pageNumber: routeType.pageNumber})
        );
        break;
    }

    if (navigate && compare.currentPageNumber !== state.currentPageNumber) {
      navigate(`/${state.currentPage?.pageStub}`);
    }

    return state;
  }
);

// WIZARD PAGE SLICE
export type WizardStates = 'OFF' | 'IDLE' | 'LOADING' | 'CONTINUE';

export type PageStates = 'IDLE' | 'VALIDATE' | 'SUCCESS';

export interface WizardPageStateProps<TStubType extends string = any> {
  pageName: string;
  pageKey?: string;
  pageStub?: TStubType;
  pageState: PageStates;
}

export interface WizardStateProps {
  wizardState: WizardStates;
  currentPageNumber: number;
  currentPage: WizardPageStateProps | undefined;
  pages: Array<WizardPageStateProps>;
}

export const initialState: WizardStateProps = {
  wizardState: 'OFF',
  currentPageNumber: 0,
  currentPage: undefined,
  pages: [],
};

export const wizardPageSlice = createSlice({
  name: wizardPageSliceName,
  initialState,
  reducers: {
    WIZARD_STATE_UPDATE: (
      state,
      action: PayloadAction<{wizardState: WizardStates}>
    ) => {
      state.wizardState = action.payload.wizardState;
    },
    WIZARD_CURRENTPAGE_VALIDATE: (state) => {
      if (state.currentPage) {
        state.currentPage.pageState = 'VALIDATE';
      }
    },
    WIZARD_CURRENTPAGE_IDLE: (state) => {
      if (state.currentPage) {
        state.currentPage.pageState = 'IDLE';
      }
    },
    WIZARD_CURRENTPAGE_VALIDATE_RESULT: (
      state,
      action: PayloadAction<{isValid: boolean}>
    ) => {
      if (state.currentPage) {
        state.currentPage.pageState = action.payload.isValid
          ? 'SUCCESS'
          : 'IDLE';
      }
    },
    WIZARD_NEXT_PAGE: (state) => {
      if (state.currentPageNumber < state.pages.length - 1) {
        state.currentPageNumber += 1;
        state.currentPage = state.pages[state.currentPageNumber];
      }
    },
    WIZARD_PREVIOUS_PAGE: (state) => {
      if (state.currentPageNumber > 0) {
        state.currentPageNumber -= 1;
        state.currentPage = state.pages[state.currentPageNumber];
      }
    },
    WIZARD_TO_PAGE: (state, action: PayloadAction<{pageNumber: number}>) => {
      const {pageNumber} = action.payload;
      const page = state.pages[pageNumber];
      if (page) {
        state.currentPageNumber = pageNumber;
        state.currentPage = state.pages[state.currentPageNumber];
      }
    },
    WIZARD_TO_STUB: (state, action: PayloadAction<{pageStub: string}>) => {
      const {pageStub} = action.payload;

      const pageNumber = state.pages.findIndex(
        (page) => page.pageStub === pageStub
      );

      if (pageNumber >= 0) {
        state.currentPageNumber = pageNumber;
        state.currentPage = state.pages[state.currentPageNumber];
      }
    },
    WIZARD_INIT: (
      state,
      action: PayloadAction<{
        pages: Array<WizardPageStateProps>;
        pageNumber?: number;
        pageStub?: string;
      }>
    ) => {
      let {pages, pageNumber = 0, pageStub} = action.payload;

      // Map and generate stubs and keys as needed.
      state.pages = pages.map((page, idx) => ({
        ...page,
        pageStub: !page.pageStub
          ? wizardStubGenerator(page.pageName)
          : page.pageStub,
        pageKey: !page.pageKey
          ? wizardStubGenerator(`${page.pageName}_${idx}`)
          : page.pageKey,
      }));

      // Page stubs takes priority over pageNumber
      if (pageStub) {
        pageNumber = state.pages.findIndex(
          (page) => page.pageStub === pageStub
        );
      }

      // Only set the page number if it's valid
      if (pageNumber >= 0 && pageNumber < pages.length) {
        state.currentPageNumber = pageNumber;
      } else {
        state.currentPageNumber = -1;
      }

      // Set current page + activate the wizard store
      state.currentPage = state.pages[state.currentPageNumber];
      state.wizardState = 'IDLE';
    },
    WIZARD_RESET: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(WIZARD_PAGE_ROUTE_THUNK.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(PURGE, () => initialState);
  },
});

// PRIVATE FUNCTIONS
const wizardStubGenerator = (name: string) => {
  return paramCase(name);
};

// EXPORTS
export const {
  WIZARD_NEXT_PAGE,
  WIZARD_PREVIOUS_PAGE,
  WIZARD_INIT,
  WIZARD_TO_STUB,
  WIZARD_TO_PAGE,
  WIZARD_CURRENTPAGE_VALIDATE,
  WIZARD_CURRENTPAGE_VALIDATE_RESULT,
  WIZARD_CURRENTPAGE_IDLE,
  WIZARD_STATE_UPDATE,
  WIZARD_RESET,
} = wizardPageSlice.actions;
export const wizardPageState = (state: RootState) => state.wizardPage;
export const wizardPageReducer = wizardPageSlice.reducer;
