import React from 'react';
import {useParams} from 'react-router-dom';

export interface RoutedStepProps {
  /**
   * Component to pass routed parameters to
   */
  component: React.ReactElement;
}

/**
 * Use this to decouple your components from routing.
 * 
 * This will automatically retrieve all available params from the route and pass it on to the supplied component.
 * @param props
 * @returns Cloned component with additional params from route supplied. Note this will override props of the same name as the param.
 */
const RoutedComponent = ({component}: RoutedStepProps) => {
  const propsFromRoute = useParams();

  const componentProps = {
    ...propsFromRoute,
  };

  return <>{React.cloneElement(component, componentProps)}</>;
};

export {RoutedComponent};
