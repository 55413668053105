import {Colours} from '@mfb/lego';
import {BlueCrossOutText} from '@mfb/cookbook';
import React from 'react';
import styled from 'styled-components';

const StyledPriceDiv = styled.div<{textColor?: string}>`
  color: ${(props) => props.textColor};
`;

interface PriceDisplayProps {
  showPriceBeforeDiscount?: boolean;
  showPrimaryText?: boolean;

  price: string;
  priceBeforeDiscount?: string;
  priceFormatString?: string;

  discountColor?: string;
}

const PriceDisplay: React.FunctionComponent<PriceDisplayProps> = ({
  price,
  priceBeforeDiscount,
  showPrimaryText = false,
  priceFormatString = '{0} first week',
  showPriceBeforeDiscount = false,
  discountColor = Colours.CHARCOAL,
}) => {
  const _showPriceBeforeDiscount =
    Boolean(priceBeforeDiscount) && showPriceBeforeDiscount;
  const _showTemplateString = Boolean(priceFormatString) && showPrimaryText;

  return (
    <>
      <StyledPriceDiv
        textColor={_showPriceBeforeDiscount ? discountColor : undefined}
      >
        {_showTemplateString
          ? priceFormatString.replace('{0}', price)
          : `${price}`}
      </StyledPriceDiv>
      {_showPriceBeforeDiscount && (
        <BlueCrossOutText text={priceBeforeDiscount ?? ''} />
      )}
    </>
  );
};

export {PriceDisplay};
