import { PersonalDetailsInputNames } from "@mfb/cookbook";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateLeadCommand, SessionBeginRequest, SessionBeginResponse } from "app";
import { updateLead, beginSession } from "features/shared";
import { WIZARD_FORM_UPDATE, WIZARD_CURRENTPAGE_VALIDATE_RESULT } from "features/wizard";
import { UseFormGetValues, FieldValues, UseFormTrigger } from "react-hook-form";
import { CustomerContactPreference } from "./state";

export const UPDATE_PERSONAL_DETAILS_THUNK = createAsyncThunk(
  `personalDetails/UPDATE_PERSONAL_DETAILS`,
  async (
    {
      getValues,
      trigger,
      sku,
      cartId,
      personalDetailsIsCustomer,
      isEmailForExistingCustomer,
    }: {
      getValues: any;
      trigger: any;
      sku?: string;
      cartId?: string;
      personalDetailsIsCustomer: boolean;
      isEmailForExistingCustomer: boolean;
    },
    {dispatch}
  ) => {
    const isValid = await trigger();

    if (
      isValid &&
      personalDetailsIsCustomer === false &&
      isEmailForExistingCustomer === false
    ) {
      const values = getValues();
      dispatch(
        WIZARD_FORM_UPDATE({
          personalDetailsFirstName: values.personalDetailsFirstName,
          personalDetailsLastName: values.personalDetailsLastName,
          personalDetailsMobileNumber: values.personalDetailsMobileNumber,
          personalDetailsEmailAddress: values.personalDetailsEmail,
          personalDetailsMarketingSmsOptIn:
            values.personalDetailsMarketingSmsContactOptIn,
        })
      );
      const updateLeadCommand = new UpdateLeadCommand({
        primaryProductSku: sku,
        firstName: values.personalDetailsFirstName,
        lastName: values.personalDetailsLastName,
        mobileNumber: values.personalDetailsMobileNumber,
        email: values.personalDetailsEmail,
        cartId: cartId,
        customerContactPreference:
          values.personalDetailsMarketingSmsContactOptIn
            ? undefined
            : CustomerContactPreference.ExcludeMarketingMobileSmsContact,
      });
      updateLead(updateLeadCommand);
      dispatch(WIZARD_CURRENTPAGE_VALIDATE_RESULT({isValid: true}));
    } else {
      dispatch(WIZARD_CURRENTPAGE_VALIDATE_RESULT({isValid: false}));
    }
  }
);

export const UPDATE_EMAIL_THUNK = createAsyncThunk(
  `personalDetails/UPDATE_EMAIL`,
  async (
    {
      sku,
      getValues,
      trigger,
    }: {
      sku: string;
      getValues: UseFormGetValues<FieldValues>;
      trigger: UseFormTrigger<FieldValues>;
    },
    {dispatch}
  ) => {
    const isValid = await trigger(PersonalDetailsInputNames.Email);
    if (isValid) {
      const email = getValues(PersonalDetailsInputNames.Email);
      const request = new SessionBeginRequest({
        email: email,
        sku: sku,
      });
      const sessionBeginResponse: SessionBeginResponse = await beginSession(
        request
      );
      dispatch(
        WIZARD_FORM_UPDATE({
          customerNumber: sessionBeginResponse.customerNumber,
          personalDetailsEmailAddress: email,
          personalDetailsHasLogin: sessionBeginResponse.hasLogin,
          personalDetailsIsCustomer: sessionBeginResponse.isCustomer,
          personalDetailsLoginUrl: sessionBeginResponse.customerRedirectUrl,
        })
      );
      return sessionBeginResponse.isCustomer;
    }
    return false;
  }
);
