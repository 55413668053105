import React from 'react';
import {useLocation} from 'react-router-dom';
import {BaseEvent, trackAnalytics} from 'utilities';

/**
 * Automatically sets up an effect to track React-Router changes.
 * Only use this once. As high up as possible!
 */
const useRouterTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    const pageViewTracking: BaseEvent = {
      event: 'page_view',
      eventModel: {
        page_title: document.title,
        page_location: location.pathname + location.search,
      },
    };
    trackAnalytics(pageViewTracking);
  }, [location]);
};

export {useRouterTracking};
export default useRouterTracking;
