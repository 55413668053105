import {loginRequest} from 'config';
import {
  SilentRequest,
  IPublicClientApplication,
  AccountInfo,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import {IAuthClientConfig} from 'app';

export const acquireToken = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const account: any = accounts[0] || {};

  if (!account || accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw new Error('User is not signed in');
  }

  const request: SilentRequest = {
    ...loginRequest,
    account,
  };

  const authResult = await instance
    .acquireTokenSilent(request)
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(request);
      }
    });

  return authResult;
};

export function getTokenConfig(bearerToken: string): IAuthClientConfig {
  const config: IAuthClientConfig = {
    getAuthorization: () => bearerToken,
  };
  return config;
}

export function getEmptyTokenConfig(): IAuthClientConfig {
  const config: IAuthClientConfig = {
    getAuthorization: () => '',
  };
  return config;
}
