import { RecipeDetailModelLegacy } from '@mfb/cookbook';
import {useLazyRecipeDetailsQuery} from 'features';
import {useRecipeDate} from './useRecipeDate';

export const useRecipeDetails = (week?: Date) => {
  const {recipeWeekIso} = useRecipeDate(week);
  const [rawRequestRecipeDetails, result] = useLazyRecipeDetailsQuery();

  const requestRecipeDetails = async (
    partition: string,
    recipeNumber: string,
    recipeVersion: string
  ): Promise<RecipeDetailModelLegacy> => {
    const response = await rawRequestRecipeDetails({
      partition: partition,
      recipeNumber: recipeNumber,
      recipeVersion: recipeVersion,
      weekDateIsoString: recipeWeekIso,
    }).unwrap();

    return {
      name: response.title,
      description: response.description,
      numberOfServes: `Serves ${response.numberOfServes}`,
      readyInTime: `Ready in ${response.readyInTime} minutes `,
      // tags and badge are intentionally swapped for rendering purposes
      badges: response.tags,
      tags: [response.badge ?? ''],
      imageUrl: response.imageUrl,
      ingredientGroups: response.ingredientGroups
        ? response.ingredientGroups.map((item) => {
            return {
              name: item.name,
              ingredients: item.ingredients
                ? item.ingredients.map((item) => item.description ?? '')
                : [],
            };
          })
        : [],
      nutritionalInfo: response.nutritionalInformation?.map(
        (item) => `${item.label} ${item.value}${item.unit}`
      ),
      steps: response.steps?.map((item) => {
        return {
          header: item.heading,
          detail: item.method,
        };
      }),
      allergens: response.allergens?.join(' '),
      unitSurcharge: response.surcharge?.hasDiscount
        ? response.surcharge?.salePrice?.totalPrice
        : response.surcharge?.listPrice?.totalPrice,
    };
  };

  return {requestRecipeDetails, recipeDetails: result};
};
