import React from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import {useAppDispatch} from 'hooks';
import {
  WIZARD_RESET,
  trackEcommerceAnalyticsThunk,
  sentryErrorTracking,
} from 'features';
import {appConfig} from 'config';

interface ErrorNavigationState {
  id: string;
  error: Error;
}

const StyledHomeButton = styled(Link)``;

export const Error = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigationState = location.state as ErrorNavigationState | undefined;

  if (navigationState) {
    sentryErrorTracking(navigationState.error);
    console.error(`Failure: ${navigationState.id}`, navigationState.error);
  }

  /**
   * Turn the wizard page manager OFF. Let React Router handle state
   * rehydration for more predictable routing (otherwise)
   * redux will take over pushing the use back into the last known error state route.
   */
  const [pageInit, setPageInit] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      if (pageInit) {
        dispatch(WIZARD_RESET());
        await dispatch(
          trackEcommerceAnalyticsThunk({
            pageName: appConfig.analytics.pageNames.errorPage,
            currentPageNumber: appConfig.analytics.pageNumbers.errorPage,
          })
        );
        setPageInit(false);
      }
    })();
  }, [dispatch, pageInit]);

  return (
    <div className="container-sm py-5">
      <h1>{`We've scrambled our eggs 🍳`}</h1>
      <p>
        There was an issue processing your request. If the issue persists please
        get in touch with our{' '}
        <a href="https://help.myfoodbag.co.nz/hc/en-nz">Customer Love</a> team.
      </p>
      <p>{`Alternatively, try restarting your order by clicking the button below.`}</p>
      <StyledHomeButton className="btn btn-primary" to="/restart">
        Restart Order
      </StyledHomeButton>
    </div>
  );
};
