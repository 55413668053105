/**
 * This enum is a copy of our CustomerContactPreference in our myfoodbag.models repository.
 * This enum is currently not being generated in our client.ts file through nswag. 
 * TODO: remove this once we can generate it in our client.ts
 */
export enum CustomerContactPreference {
    ExcludeMarketingEmailContact = 1, 
    ExcludeMarketingMobileSmsContact = 2, 
    ExcludeMobileAppReminders = 4, 
    ExcludeMobileAppAccountRelated = 8, 
    ExcludeMobileAppDeliveryInformation = 16, 
    ExcludeMobileAppSpecialOffersAndPromotions = 32, 
    ExcludeMobileAppSupport = 64, 
  }