import React, {PropsWithChildren} from 'react';
import {Colours} from '@mfb/lego';
import styled from 'styled-components';

const StyledFooterWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.backgroundSecondary};
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: ${({theme}) => `${theme.spacing.small} ${theme.spacing.large}`};
  border-top: 1px solid ${Colours.SECONDARY_DARK_CREAM};
  border-radius: 10px 10px 0px 0px;
`;

const StyledOrderFormFooter = styled.div`
  width: 100%;
  max-width: ${({theme}) => theme.layout.containerMaxWidths.xl};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrderFormFooter: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <StyledFooterWrapper>
      <StyledOrderFormFooter>{children}</StyledOrderFormFooter>
    </StyledFooterWrapper>
  );
};

export {OrderFormFooter};
