import {CreatePurchaseRequest, CreateSubscriptionResponse} from 'app';
import {ApiClient, retryable} from 'features';

export const createSubscription = async (
  sku: string,
  request: CreatePurchaseRequest,
  bearerToken: string
): Promise<CreateSubscriptionResponse> => {
  const purchaseClient = ApiClient(bearerToken);
  return retryable(() => purchaseClient.createPurchase(sku, request));
};
