import {
  buildEcommerceSuccessEvent,
  EcommercePurchaseType,
  EcommerceSuccessEventProps,
} from './events/ecommerceCheckoutEvent';
import {
  buildEcommerceEvent,
  EcommerceBaseEventType,
  EcommerceCheckoutEventProps,
  EcommerceCheckoutType,
} from './events/ecommerceCheckoutEvent';

export type BaseEvent = {event: string; [dataKey: string]: any};

type TrackAnalyticsEvent =
  | EcommerceBaseEventType<EcommercePurchaseType>
  | EcommerceBaseEventType<EcommerceCheckoutType>
  | BaseEvent;
const trackAnalytics = (event: TrackAnalyticsEvent) => {
  // Heads up dataLayer is constructed in the index.html
  if (window.dataLayer) {
    window.dataLayer.push(event);
  }
};

const trackEcommerceAnalytics = (props: EcommerceCheckoutEventProps) =>
  trackAnalytics(buildEcommerceEvent(props));

const trackEcommerceSuccessAnalytics = (props: EcommerceSuccessEventProps) =>
  trackAnalytics(buildEcommerceSuccessEvent(props));

export {
  trackAnalytics,
  trackEcommerceAnalytics,
  trackEcommerceSuccessAnalytics,
};
