/* eslint-disable @typescript-eslint/no-unused-expressions */
//@ts-nocheck
import {appConfig} from 'config';

export const loadVwoTag = () => {
  if (process.env.NODE_ENV === 'production') {
    window._vwo_code =
      window._vwo_code ||
      (function () {
        let account_id = appConfig.analytics.vwoAccountId,
          version = 1.1,
          settings_tolerance = 2000,
          library_tolerance = 2500,
          use_existing_jquery = false,
          is_spa = 1,
          hide_element = 'body',
          /* DO NOT EDIT BELOW THIS LINE */
          f = false,
          d = document,
          code = {
            use_existing_jquery: function () {
              return use_existing_jquery;
            },
            library_tolerance: function () {
              return library_tolerance;
            },
            finish: function () {
              if (!f) {
                f = true;
                let e = d.getElementById('_vis_opt_path_hides');
                if (e) e.parentNode.removeChild(e);
              }
            },
            finished: function () {
              return f;
            },
            load: function (e) {
              let t = d.createElement('script');
              t.fetchPriority = 'high';
              t.src = e;
              t.type = 'text/javascript';
              t.innerText;
              t.onerror = function () {
                _vwo_code.finish();
              };
              d.getElementsByTagName('head')[0].appendChild(t);
            },
            init: function () {
              window.settings_timer = setTimeout(function () {
                _vwo_code.finish();
              }, settings_tolerance);
              let e = d.createElement('style'),
                t = hide_element
                  ? hide_element +
                    '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}'
                  : '',
                i = d.getElementsByTagName('head')[0];
              e.setAttribute('id', '_vis_opt_path_hides');
              e.setAttribute('type', 'text/css');
              if (e.styleSheet) e.styleSheet.cssText = t;
              else e.appendChild(d.createTextNode(t));
              i.appendChild(e);
              this.load(
                'https://dev.visualwebsiteoptimizer.com/j.php?a=' +
                  account_id +
                  '&u=' +
                  encodeURIComponent(d.URL) +
                  '&f=' +
                  +is_spa +
                  '&r=' +
                  Math.random() +
                  '&vn=' +
                  version
              );
              return settings_timer;
            },
          };
        window._vwo_settings_timer = code.init();
        return code;
      })();
  }
};
