import {useMsal} from '@azure/msal-react';
import React from 'react';
import {useAppDispatch} from 'hooks';
import {persistor} from 'app';
import {beginLogout} from 'config';
import {Loading} from 'features';

const Restart = () => {
  const {instance} = useMsal();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (instance) {
      (async () => {
        try {
          // Delete the session store
          await persistor.purge();
          await persistor.flush();
          // Redirect / Refresh Page / All reducers are reset.
          await beginLogout(instance);
        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          }
        }
      })();
    }
  }, [dispatch, instance]);

  return <Loading />;
};

export {Restart};
