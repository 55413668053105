import React from 'react';
import {Navigate} from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
  useThunkDispatch,
  useAuthToken,
} from 'hooks';
import {
  paymentDetailsState,
  getPurchaseUriThunk,
  trackEcommerceAnalyticsThunk,
  Loading,
} from 'features';
import {appConfig, beginLogout} from 'config';
import {useMsal} from '@azure/msal-react';

export interface PaymentRedirectProps {
  replace?: boolean;
}

const PaymentRedirect: React.FunctionComponent<PaymentRedirectProps> = ({
  replace = true,
}) => {
  const dispatch = useAppDispatch();
  const dispatchThunk = useThunkDispatch(dispatch);
  const state = useAppSelector(paymentDetailsState);
  const {instance} = useMsal();
  const {bearerToken} = useAuthToken();
  const [isInit, setIsInit] = React.useState(true);

  const isAuthLoaded = Boolean(bearerToken) && Boolean(instance);

  React.useEffect(() => {
    (async () => {
      if (!isInit) {
        if (state.redirectUri && !state.error) {
          if (replace) {
            location.replace(state.redirectUri);
          } else {
            location.assign(state.redirectUri);
          }
        }
      }
    })();
  }, [state.redirectUri, state.error, replace, isInit, dispatch]);

  /**
   * Will dispatch and ensure that the purchase URL is
   * matched with the currently logged in user; otherwise logout
   * B2C and try redispatching.
   */
  React.useEffect(() => {
    (async () => {
      if (isInit && bearerToken && isAuthLoaded) {
        const result = await dispatchThunk(getPurchaseUriThunk(bearerToken));
        if (result.logoutRequired) {
          await beginLogout(instance, window.location.pathname);
        } else {
          setIsInit(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bearerToken, isAuthLoaded, isInit]);

  React.useEffect(() => {
    (async () => {
      if (isInit) {
        // Qualified Page Load
        await dispatch(
          trackEcommerceAnalyticsThunk({
            pageName: appConfig.analytics.pageNames.paymentRedirect,
            currentPageNumber: appConfig.analytics.pageNumbers.paymentRedirect,
          })
        );
      }
    })();
  }, [dispatch, isInit]);

  if (!isInit && state.error) {
    return (
      <Navigate
        replace
        to={'/error'}
        state={{id: 'payment-redirect', error: state.error}}
      />
    );
  } else {
    return <Loading />;
  }
};

export {PaymentRedirect};
