import React, {useEffect} from 'react';
import {
  useAppDispatch,
  useAppSelector,
  useAuthToken,
  useSkuFromUrlEffect,
} from 'hooks';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  PageStates,
  wizardFormData,
  trackEcommerceAnalyticsThunk,
  Loading,
  UPDATE_EMAIL_THUNK,
  UPDATE_PERSONAL_DETAILS_THUNK,
} from 'features';
import {debounce} from 'lodash';
import styled from 'styled-components';
import {appConfig, beginLogout} from 'config';
import {useMsal} from '@azure/msal-react';
import {buildLoginfromCheckoutEvent} from 'utilities';
import {useThunkDispatch} from '../../../hooks/useDispatch';
import { PersonalDetailsForm, PersonalDetailsInputNames, ValueProposition, ValuePropositionIcons } from '@mfb/cookbook';
import { Button } from '@mfb/lego';

const DEBOUNCE_TIME = 750;

const VALUE_PROPOSITION_YOUR_CHOICE = {
  headerText: 'Your Choice',
  bodyText: 'A variety of fresh, delicious recipes to choose from each week.',
};

const VALUE_PROPOSITION_FLEXIBILITY = {
  headerText: 'Plenty of Flexibility',
  bodyText:
    "You'll have until midnight Monday to decide on your upcoming delivery.",
};

const StyledContainer = styled.div`
  min-height: 100%;
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;
`;

const StyledRow = styled.div`
  height: 100%;
`;

const WelcomeBackDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${c=>c.theme.colorsV2.neutral[300]};
  border-radius: 12px;
  background-color: ${c=>c.theme.colorsV2.neutral[100]};
  padding: 32px 40px;

  row-gap: ${(props) => props.theme.spacing.large};

  font-family: ${c=>c.theme.fonts.default};
  color: ${c=>c.theme.colorsV2.neutral[600]};

  .__header{
    font-size: 19px;
    font-weight: ${c=>c.theme.typography.fontWeight[700]};
    color: ${c=>c.theme.colorsV2.neutral[900]};
  }

  .__subheader{
    font-size: ${c=>c.theme.typography.fontSize[400]};
    font-weight: ${c=>c.theme.typography.fontWeight[700]};
  }

  .__sign-in-button{
    margin-top: 8px;
  }

  @media ${(c) => c.theme.layout.deviceMediaQueries.max.lg} {
    padding: 32px 32px;
  }
`;

const FlexColumnBetween = styled.div`
  display: flex;
  height: 100%;
  gap: ${(props) => props.theme.spacing.xlarge};
  flex-direction: column;
  justify-content: space-between;
`;

const TermsAndConditionsDiv = styled.div`
  padding-bottom: ${(props) => props.theme.spacing.xxlarge};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.large};
`;

const LeftAlignedValuePropDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xxlarge};

  @media ${(props) => props.theme.layout.deviceMediaQueries.min.lg} {
    display: none;
  }
`;

const RightAlignedValuePropDiv = styled.div`
  width: 60%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(${(props) => props.theme.spacing.jumbo} * 2);
  gap: ${(props) => props.theme.spacing.jumbo};
`;

const enum PersonalDetailsErrors {
  FirstNameRequired = 'First name is required.',
  LastNameRequired = 'Last name is required.',
  MobileNumberRequired = 'Mobile number is required.',
  InvalidMobileNumber = 'Mobile number must be a valid NZ mobile number.',
  EmailRequired = 'Email address is required.',
  InvalidEmail = 'Please enter a valid email address.',
}

const validationSchema = yup.object().shape({
  personalDetailsFirstName: yup
    .string()
    .required(PersonalDetailsErrors.FirstNameRequired),
  personalDetailsLastName: yup
    .string()
    .required(PersonalDetailsErrors.LastNameRequired),
  personalDetailsMobileNumber: yup
    .string()
    .required(PersonalDetailsErrors.MobileNumberRequired)
    .matches(
      /^(?<countrycode>(\+|00)?(64))?(?<mob_start>0?2[0-9]{1,3})(?<mob_end>[0-9]{6})$/,
      PersonalDetailsErrors.InvalidMobileNumber
    ),
  personalDetailsEmail: yup
    .string()
    .required(PersonalDetailsErrors.EmailRequired)
    .email(PersonalDetailsErrors.InvalidEmail),
});

export interface PersonalDetailsStepProps {
  pageState: PageStates;
  stub?: string;
  pageNumber?: number;
}

export const PersonalDetailsStep: React.FunctionComponent<
  PersonalDetailsStepProps
> = ({pageState}) => {
  const pageData = useAppSelector(wizardFormData);
  const dispatch = useAppDispatch();
  const dispatchThunk = useThunkDispatch(dispatch);
  const useFormMethods = useForm({resolver: yupResolver(validationSchema)});
  const {isLoading: isAuthLoading, isAuthenticated} = useAuthToken({
    handleErrors: false,
  });
  const {instance} = useMsal();

  const {trigger, getValues, clearErrors, register, setValue, formState} = {
    ...useFormMethods,
  };

  const {errors} = formState;
  const [isEmailLoading, setIsEmailLoading] = React.useState(false);
  const isEmailForExistingCustomer = React.useRef(false);

  /* Allow for users to land directly on this page */
  /* Sync SKU to State */
  useSkuFromUrlEffect(true);

  const verifyEmail = async () => {
    // Always reevaluate the email address on this page.
    isEmailForExistingCustomer.current = await dispatchThunk(
      UPDATE_EMAIL_THUNK({
        sku: pageData.sku ?? '',
        getValues,
        trigger,
      })
    );
  };

  /**
   * Effect that tracks the component pageInit
   */
  const [pageInit, setPageInit] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      if (pageInit) {
        await dispatch(
          trackEcommerceAnalyticsThunk({
            pageName: appConfig.analytics.pageNames.personalDetails,
          })
        );
        setPageInit(false);
      }
    })();
  }, [dispatch, pageInit]);

  /**
   * On return to this screen from another screen, refill the existing redux data into the form.
   */
  useEffect(() => {
    if (pageData !== undefined) {
      setValue(
        PersonalDetailsInputNames.FirstName,
        pageData.personalDetailsFirstName
      );
      setValue(
        PersonalDetailsInputNames.LastName,
        pageData.personalDetailsLastName
      );
      setValue(
        PersonalDetailsInputNames.MobileNumber,
        pageData.personalDetailsMobileNumber
      );
      setValue(
        PersonalDetailsInputNames.MarketingSmsContactOptIn,
        pageData.personalDetailsMarketingSmsOptIn
      );

      if (pageData.personalDetailsEmailAddress) {
        setValue(
          PersonalDetailsInputNames.Email,
          pageData.personalDetailsEmailAddress
        );
        setIsEmailLoading(true);
        verifyEmail().finally(() => setIsEmailLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * REQUIRED CALLBACK
   * Validate step/form fields and submit the validation result to redux.
   */
  const validateStep = React.useCallback(async () => {
    dispatch(
      UPDATE_PERSONAL_DETAILS_THUNK({
        getValues,
        trigger,
        sku: pageData.sku,
        cartId: pageData.cartId,
        isEmailForExistingCustomer: isEmailForExistingCustomer.current,
        personalDetailsIsCustomer: pageData.personalDetailsIsCustomer ?? false,
      })
    );
  }, [
    dispatch,
    getValues,
    pageData.cartId,
    pageData.personalDetailsIsCustomer,
    pageData.sku,
    trigger,
  ]);

  /**
   * REQUIRED EFFECT
   * Respond to external validation requests i.e. from @see OrderForm
   */
  React.useEffect(() => {
    (async () => {
      if (pageState === 'VALIDATE' && !isEmailLoading) {
        await verifyEmail();
        validateStep();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateStep, pageState, isEmailLoading]);

  //Debounce validation on form input.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebounce = React.useCallback(
    debounce((inputName: string) => {
      clearErrors(inputName);
      trigger(inputName);
    }, DEBOUNCE_TIME),
    []
  );

  //Triggered on input to any of the three fields.
  const onInputChange = React.useCallback(
    async (inputName: string) => {
      clearErrors(inputName);
      onDebounce(inputName);
    },
    [clearErrors, onDebounce]
  );

  // When the user's email is changed; And they're logged in - quickly log out.
  const onEmailBlur = (e: Event) => {
    (async () => {
      try {
        setIsEmailLoading(true);
        const isValidEmail = await trigger(PersonalDetailsInputNames.Email);
        const newEmail = e.target as HTMLInputElement;
        const emailHasChanged =
          pageData.personalDetailsEmailAddress !== newEmail.value;

        await verifyEmail();

        if (isValidEmail && isAuthenticated && emailHasChanged) {
          const {pathname} = window.location;
          await beginLogout(instance, pathname);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      } finally {
        setIsEmailLoading(false);
      }
    })();
  };

  const onLoginUser = async () => {
    await dispatch(
      trackEcommerceAnalyticsThunk({
        pageName: appConfig.analytics.pageNames.personalDetails,
      })
    );
    if (pageData.personalDetailsLoginUrl) {
      location.replace(pageData.personalDetailsLoginUrl);
    }
  };

  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <StyledContainer className="container">
      <h1>{"Let's get you started!"}</h1>
      <StyledRow className="row">
        <FlexColumnBetween className="col-lg-6 col">
          <PersonalDetailsForm
            className=""
            register={register}
            firstNameError={
              errors.personalDetailsFirstName
                ? String(errors.personalDetailsFirstName.message)
                : undefined
            }
            lastNameError={
              errors.personalDetailsLastName
                ? String(errors.personalDetailsLastName.message)
                : undefined
            }
            mobileNumberError={
              errors.personalDetailsMobileNumber
                ? String(errors.personalDetailsMobileNumber.message)
                : undefined
            }
            emailError={
              errors.personalDetailsEmail
                ? String(errors.personalDetailsEmail.message)
                : undefined
            }
            isEmailLoading={isEmailLoading}
            onChangeCallback={onInputChange}
            onEmailBlurCallback={onEmailBlur}
          >
            {pageData.personalDetailsIsCustomer && (
              <WelcomeBackDiv>
                <span className='__header'>Welcome back, nice to see you again!</span>
                <span className='__sub-header'>
                  You have an existing account with us, click the Sign In button
                  to continue.
                </span>
                <Button
                  label="Sign in"
                  className='__sign-in-button'
                  size="lg"
                  onClick={() => {
                    onLoginUser();
                    buildLoginfromCheckoutEvent();
                  }}
                ></Button>
              </WelcomeBackDiv>
            )}
          </PersonalDetailsForm>
          <LeftAlignedValuePropDiv>
            <ValueProposition
              iconType={ValuePropositionIcons.YourChoice}
              iconSize="40px"
              {...VALUE_PROPOSITION_YOUR_CHOICE}
            />
            <ValueProposition
              iconType={ValuePropositionIcons.Flexibility}
              iconSize="40px"
              {...VALUE_PROPOSITION_FLEXIBILITY}
            />
          </LeftAlignedValuePropDiv>
          <TermsAndConditionsDiv>
            <span>
              {'By continuing I agree to the '}
              <a
                href="https://www.myfoodbag.co.nz/terms"
                target="_blank"
                rel="noreferrer"
              >
                {'Terms & Conditions'}
              </a>
              {' and '}
              <a
                href="https://www.myfoodbag.co.nz/privacy"
                target="_blank"
                rel="noreferrer"
              >
                {'Privacy Policy'}
              </a>
              .
            </span>
          </TermsAndConditionsDiv>
        </FlexColumnBetween>

        <div className="col-lg-6 d-none d-lg-block">
          <RightAlignedValuePropDiv>
            <ValueProposition
              iconType={ValuePropositionIcons.YourChoice}
              iconSize="40px"
              {...VALUE_PROPOSITION_YOUR_CHOICE}
            />
            <ValueProposition
              iconType={ValuePropositionIcons.Flexibility}
              iconSize="40px"
              {...VALUE_PROPOSITION_FLEXIBILITY}
            />
          </RightAlignedValuePropDiv>
        </div>
      </StyledRow>
    </StyledContainer>
  );
};
