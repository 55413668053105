import _ from 'lodash';
import {CalculateCostModelDiscountType, CalculateCostResponse} from 'app';
import {WIZARD_FORM_UPDATE, useCalculateCostQuery} from 'features';
import {SessionStorageIdentifiers as sessionIds} from 'utilities';
import {UseAppDispatchType, useAppDispatch, useQueryParamSync} from 'hooks';
import {useEffect} from 'react';

export interface DefaultDiscountObject {
  code: string | undefined;
  type: CalculateCostModelDiscountType;
}

export interface DiscountObject {
  id: number;
  code: string;
  description: string;
  valueOff: number;
  discountType: number;
  valueOffType: number;
  restrictions: any[];
  expires: Date;
}

interface UseCalculateCostProps {
  sku?: string;
  defaultPromoCode?: DefaultDiscountObject;
}

interface UseCalculateCostOptions {
  condition?: boolean;
}

const calculateDiscountExpiry = (minutes: number = 60) => {
  const expiryTime = minutes * 60000;
  return new Date(new Date().getTime() + expiryTime);
};

const getStorageDiscount = (
  storageKey: string,
  storageType: 'session' | 'local' = 'local',
  linkedKeys: Array<string> = []
) => {
  let storage: Storage;

  switch (storageType) {
    case 'session':
      storage = sessionStorage;
      break;
    default:
      storage = localStorage;
      break;
  }

  const discountJSON = storage.getItem(storageKey);

  try {
    if (discountJSON) {
      const storedDiscount = JSON.parse(discountJSON) as DiscountObject;

      if (!storedDiscount) {
        return undefined;
      }

      const isExpired =
        storedDiscount && new Date(storedDiscount.expires) <= new Date();

      if (isExpired) {
        storage.removeItem(storageKey);
        linkedKeys.forEach((linkedKey) => storage.removeItem(linkedKey));
        return undefined;
      }

      return storedDiscount;
    }
  } catch (err) {
    console.error(
      `Found a discount object ${storageKey} in ${storageType} storage but failed to parse `,
      discountJSON,
      err
    );
  }
};

/**
 * Builds Promotional Discounts as per the objects stored in the browser
 * @param queryPromo Any promotion from a URL
 * @param defaultPromoCode Any promotion defaults
 * @returns
 */
const getDiscount = (
  queryPromo?: string,
  defaultPromoCode?: DefaultDiscountObject
): DefaultDiscountObject | undefined => {
  let discountObject: DefaultDiscountObject | undefined;

  const websiteTrackedDiscount = getStorageDiscount(
    sessionIds.MFBDiscountObjectLocalStorage,
    'local',
    [sessionIds.MFBDiscountLocalStorage]
  );

  const orderFormSessionDiscount = getStorageDiscount(
    sessionIds.MFBOrderFormSessionDiscount,
    'session'
  );

  /** Get the discount (RAF or Promo) with the highest priority:
   * > query param
   * > User selected
   * > local storage
   * > session storage
   */
  if (queryPromo) {
    discountObject = {
      code: queryPromo,
      type: CalculateCostModelDiscountType._2,
    };
  } else if (defaultPromoCode && defaultPromoCode.code) {
    discountObject = defaultPromoCode;
  } else if (websiteTrackedDiscount) {
    discountObject = {
      code: websiteTrackedDiscount.code,
      type: CalculateCostModelDiscountType._2,
    };
  } else if (orderFormSessionDiscount) {
    discountObject = {
      code: orderFormSessionDiscount.code,
      type: CalculateCostModelDiscountType._2,
    };
  }

  const hasDiscountObjectChanged =
    (discountObject && !orderFormSessionDiscount) ||
    (discountObject &&
      !_.isEqual(
        _.pick(discountObject, ['code', 'type']),
        _.pick(orderFormSessionDiscount, ['code', 'type'])
      ));

  // No or Different Session Discount? Save the calculated discount for X minutes.
  // This is to protect against the promo query param being accidentally dropped.
  if (hasDiscountObjectChanged) {
    sessionStorage.setItem(
      sessionIds.MFBOrderFormSessionDiscount,
      JSON.stringify({...discountObject, expires: calculateDiscountExpiry(60)})
    );
  }

  return discountObject;
};

const syncToWizardState = (
  dispatch: UseAppDispatchType,
  currentData: CalculateCostResponse,
  discount: DefaultDiscountObject | undefined = undefined
) => {
  // If the discount doesn't work, nuke the discount from session storage.
  if (!currentData.discount) {
    sessionStorage.removeItem(sessionIds.MFBOrderFormSessionDiscount);
    discount = undefined;
  }

  dispatch(
    WIZARD_FORM_UPDATE({
      defaultDiscountObject: discount,
      price: {
        shipping: currentData.shippingTotal,
        discount: currentData.totalDiscount,
        subtotal: currentData.shippingTotal + currentData.subtotal,
        total: currentData.total,
        voucher: currentData.voucher?.valueOff,
      },
    })
  );
};

export const useCalculateCost = (
  {sku, defaultPromoCode}: UseCalculateCostProps,
  options?: UseCalculateCostOptions
) => {
  // Get Promotions from URL
  const dispatch = useAppDispatch();
  const queryPromoForPr = useQueryParamSync('pr');
  const queryPromoForPromo = useQueryParamSync('promo');
  const queryPromo = queryPromoForPr ?? queryPromoForPromo ?? undefined;

  const discount = getDiscount(queryPromo, defaultPromoCode);

  const {isSuccess, isFetching, currentData} = useCalculateCostQuery(
    {
      sku,
      discountCode: discount?.code,
      discountType: discount?.type,
    },
    {skip: Boolean(options?.condition)}
  );

  useEffect(() => {
    if (isSuccess && !isFetching) {
      syncToWizardState(dispatch, currentData, discount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching]);

  return {
    isSuccess,
    isFetching,
    pricing: {
      code: currentData?.discount?.code,
      promoMessage: currentData?.discount?.message,
      subtotal:
        (currentData?.shippingTotal ?? 0) + (currentData?.subtotal ?? 0),
      total: (currentData?.total ?? 0) + (currentData?.totalDiscount ?? 0),
      discount: currentData?.totalDiscount ?? 0,
      discountedTotal: currentData?.total ?? 0,
      hasDiscount: (currentData?.totalDiscount ?? 0) > 0,
    },
  };
};
