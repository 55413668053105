export enum SessionStorageIdentifiers {
  MFBOrderSummaryInformation = 'MFB_ORDER_SUMMARY_INFORMATION',
  MFBOrderCustomerValidation = 'MFB_ORDER_CUSTOMER_VALIDATION',
  MFBDiscountLocalStorage = 'mfb_discount',
  MFBDiscountObjectLocalStorage = 'mfb_discount_object',
  MFBOrderFormSessionDiscount = 'orderform_session_discount_object'
}

/**
 * Returns JS Objects from storage, falls back to
 * @param identifier session identifier key
 * @param storageProvider the storage provider i.e. `local` | `session`
 * @returns
 */
export function loadState<TReturnType>(
  identifier: SessionStorageIdentifiers,
  storageProvider: Storage = sessionStorage
): TReturnType | String | undefined {
  try {
    return JSON.parse(storageProvider.getItem(identifier) ?? '');
  } catch (error) {
    if (error instanceof SyntaxError) {
      const fallback = storageProvider.getItem(identifier);
      return fallback ? fallback : undefined;
    }
    return undefined;
  }
}

/**
 * Stores JS Objects as JSON Strings against a known identifier into storage.
 * @param identifier session identifier key
 * @param state the object you wish to store
 * @param storageProvider the storage provider i.e. `local` | `session`
 * @returns if state was commited to storage successfully.
 */
export function saveState<TStateType>(
  identifier: SessionStorageIdentifiers,
  state: TStateType,
  storageProvider: Storage = sessionStorage
): boolean {
  try {
    storageProvider.setItem(identifier, JSON.stringify(state));
    return true;
  } catch {
    return false;
  }
}

/**
 * Clears all known values from @see SessionStorageIdentifiers from storage.
 * @param storageProvider the storage provider i.e. `local` | `session`
 */
export function clearState(storageProvider: Storage = sessionStorage) {
  for (const sessionStorageKey of Object.values(SessionStorageIdentifiers)) {
    storageProvider.removeItem(sessionStorageKey);
  }
}
