//@ts-nocheck
import {appConfig} from 'config';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

export const loadSentryConfig = () => {
  if (appConfig.analytics.sentryDSN) {
    Sentry.init({
      dsn: appConfig.analytics.sentryDSN,
      normalizeDepth: 10, // deep you want your state content to be
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};
