import {WIZARD_FORM_UPDATE} from 'features';
import {useEffect} from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {useAppDispatch} from './useDispatch';
import {useProducts} from './useProducts';
import {useQueryParamSync} from './useQueryParamSync';
import {basename} from 'config';

/**
 * Extracts and Validates a SKU provided by ?sku=XXX
 * Supports itemNumbers as well as a limited of Historical Skus
 * @param restartFlowOnError if sku invalid, error and redirect back to basename of site.
 */
export const useSkuFromUrlEffect = (restartFlowOnError: boolean = false) => {
  /* If SKU exists, delete it from URL and sync state */
  const {productsCollection} = useProducts();
  const productSku = useQueryParamSync('sku')?.toUpperCase().trim();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      if (productSku && productsCollection && productsCollection.length) {
        const historicalSkuDictionary: Record<string, string> = {
          RMM10004: 'K00000004',
          RMM10001: 'K00000001',
          RMM10002: 'K00000002',
          RMM10003: 'K00000003',
          MFB9511: 'M00000051',
          MFB9510: 'M00000052',
          MFB4392: 'M00000022',
          MFB0273: 'M00000021',
          MFB3009: 'M00000020',
          MFB9484: 'K00000005',
          MFB9487: 'K00000008',
          MFB9485: 'K00000006',
          MFB9488: 'K00000009',
          MFB9486: 'K00000007',
          MFB9489: 'K00000010',
          MFB6962: 'M00000006',
          MFB3264: 'M00000010',
          MFB4588: 'M00000011',
          MFB4687: 'M00000019',
          MFB9468: 'M00000014',
          MFB9490: 'M00000015',
          MFB8345: 'M00000013',
          MFB8344: 'M00000012',
        };

        const historicalSkuItemNumber = historicalSkuDictionary[productSku];

        const selectedProduct = productsCollection.find(
          (_product) =>
            _product.itemNumber === productSku ||
            (historicalSkuItemNumber &&
              _product.itemNumber === historicalSkuItemNumber)
        );

        if (selectedProduct) {
          await dispatch(
            WIZARD_FORM_UPDATE({
              sku: selectedProduct.itemNumber,
              nights: selectedProduct.nights,
              productName: selectedProduct.name,
            })
          );
        } else {
          console.warn(
            `Query Param: ?sku=${productSku} is not a valid product. ${
              restartFlowOnError ? `Redirecting to ${basename}.` : ''
            }`
          );

          if (restartFlowOnError) {
            navigate(
              {
                pathname: '/create-bag',
                search: createSearchParams(searchParams).toString(),
              },
              {
                replace: true,
              }
            );
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSku, productsCollection.length]);
};
