import { yupResolver } from '@hookform/resolvers/yup';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeliverySlotsGroupedSummary,
  DeliverySlotsIncludeBufferGroupedSummary,
  ProductDeliverySlotDto,
  RecipeDto,
  UpdateLeadCommand,
} from 'app';
import classnames from 'classnames';
import { appConfig } from 'config';
import { format } from 'date-fns';
import {
  PageStates,
  WIZARD_CURRENTPAGE_VALIDATE_RESULT,
  WIZARD_FORM_UPDATE,
  getDeliverySlotsForProductAndAddress,
  getRecipeForBufferSlot,
  searchAddress,
  trackEcommerceAnalyticsThunk,
  updateLead,
  verifyAddress,
  wizardFormData,
  wizardFormDeliveryDetails,
  wizardFormPersonalDetails,
} from 'features';
import { useAppDispatch, useAppSelector, useErrorMessage } from 'hooks';
import { debounce, isEmpty } from 'lodash';
import React from 'react';
import { FieldValues, UseFormGetValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  DeliverySlotsGroupedSummary as CookbookDeliverySlotGroupedSummary,
  DayOfWeek,
} from '@mfb/cookbook';
import {
  AddressSuggestionDto,
  BufferSalesModal,
  BufferSalesSubsection,
  DeliveryAddressAutocomplete,
  DeliverySlotSelectorDropDown,
  DeliverySlotSelectorResult,
} from '@mfb/cookbook';
import { AsyncButton, CarouselItemModel, RecipeCarouselItem } from '@mfb/lego';

const DEBOUNCE_TIME = 275;

export enum DeliveryDetailsInputNames {
  DeliveryAddress = 'deliveryAddress',
  DeliveryPafId = 'deliveryPafId',
  DeliveryAddressId = 'deliveryAddressId',
  DeliveryInstructions = 'deliveryInstructions',
  DeliveryDateId = 'deliveryDateId',
  DeliverySlotId = 'deliverySlotId',
  DeliveryDateDescription = 'deliveryDateDescription',
}

const enum DeliveryDetailsErrors {
  DeliveryAddressIdIsRequired = 'Please enter a delivery address to continue!',
  DeliveryAddressIsOutSideDeliveryZone = "We're sorry, we don't deliver to you just yet! We have noted your address and will let you know when we can get the goodness to your door.",
  DeliveryDateIdIsRequired = 'Please let us know when to deliver!',
}

const DeliveryDetailsSuccessMessage: string =
  'Great! We can deliver deliciousness to you!';

const deliveryDetailsValidationSchema = yup.object().shape({
  [DeliveryDetailsInputNames.DeliveryAddressId]: yup
    .number()
    .moreThan(0, DeliveryDetailsErrors.DeliveryAddressIsOutSideDeliveryZone)
    .required(DeliveryDetailsErrors.DeliveryAddressIdIsRequired),
  [DeliveryDetailsInputNames.DeliveryDateId]: yup
    .number()
    .required(DeliveryDetailsErrors.DeliveryDateIdIsRequired),
});

interface DeliveryDetailsScreenProps {
  pageState: PageStates;
  stub?: string;
  pageNumber?: number;
}

export const DeliveryDetailsStep: React.FunctionComponent<
  DeliveryDetailsScreenProps
> = ({ pageState }) => {
  const orderFormData = useAppSelector(wizardFormData);
  const { emailAddress } = useAppSelector(wizardFormPersonalDetails);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    formState,
    trigger,
    setValue,
    clearErrors,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(deliveryDetailsValidationSchema),
  });

  const { errors } = formState;
  const errorsCallback = useErrorMessage(errors);

  const [addressSuggestions, setAddressSuggestions] = React.useState<
    Array<AddressSuggestionDto>
  >([]);
  const [isSearchingAddress, setIsSearchingAddress] = React.useState(false);
  const [isValidAddress, setIsValidAddress] = React.useState(false);
  const [isFindingDeliverySlots, setIsFindingDeliverySlots] =
    React.useState(false);
  const [availableDeliverySlots, setAvailableDeliverySlots] = React.useState<
    Array<CookbookDeliverySlotGroupedSummary>
  >([]);

  const [isBufferSaleModalOpen, setIsBufferSaleModalOpen] =
    React.useState(false);
  const [isBufferSaleSubsectionOpen, setIsBufferSaleSubsectionOpen] =
    React.useState(false);
  const [isBufferCTAButtonDisplay, setIsBufferCTAButtonDisplay] =
    React.useState(false);
  const [isBufferCTAButtonLoading, setIsBufferCTAButtonLoading] =
    React.useState<boolean>(false);
  const isBufferSaleAvailable = React.useRef<boolean>(false);
  const bufferDeliveryDate = React.useRef<string>('');
  const bufferSaleDeliverySlots = React.useRef<Array<ProductDeliverySlotDto>>(
    []
  );
  const selectedRegularDeliverySlot =
    React.useRef<DeliverySlotSelectorResult>();
  const bufferSaleCarouselItems = React.useRef<Array<CarouselItemModel>>([]);
  const selectedSku = orderFormData.sku ?? '';
  const selectedNights = orderFormData.nights;
  const availableBufferSlot = React.useRef<ProductDeliverySlotDto>();
  const selectedBufferSlot = React.useRef<ProductDeliverySlotDto>();
  const matchDeliverySlotCheck = React.useRef<boolean>(false);
  const [isSlotDropDownOpen, setIsSlotDropDownOpen] = React.useState(false);
  const pageData = useAppSelector(wizardFormDeliveryDetails);

  /**
   * Effect that tracks the component pageInit
   */
  const [pageInit, setPageInit] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      if (pageInit) {
        await dispatch(
          trackEcommerceAnalyticsThunk({
            pageName: appConfig.analytics.pageNames.deliveryDetails,
          })
        );
        setPageInit(false);
      }
    })();
  }, [dispatch, pageInit]);

  React.useEffect(() => {
    //On return to this screen from another screen, refill the existing redux data into the form.
    if (pageData !== undefined) {
      setValue('deliveryInstructions', pageData.deliveryInstructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UPDATE_DELIVERY_DETAILS_THUNK = createAsyncThunk(
    `deliveryDetails/UPDATE_DELIVERY_DETAILS`,
    async (
      {
        email,
        getValues,
        trigger,
      }: {
        email: string;
        getValues: UseFormGetValues<FieldValues>;
        trigger: any;
      },
      { dispatch }
    ) => {
      const isValid = await trigger();
      let WizardFromUpdateBody = {};

      if (isValid) {
        const values = getValues();
        const request = new UpdateLeadCommand({
          email: email,
          addressId: values.deliveryAddressId,
          deliveryInstructions: values.deliveryInstructions,
        });
        await updateLead(request);

        WizardFromUpdateBody = {
          deliveryAddress: values.deliveryAddress,
          deliveryPafId: values.deliveryPafId,
          deliveryAddressId: values.deliveryAddressId,
          deliveryInstructions: values.deliveryInstructions,
          deliveryDateId: '',
          // we always want the regular delivery slot to be selected no matter it's buffer or not
          overrideDeliverySlotId: values.deliverySlotId,
          deliveryDateDescription: '',
        };

        if (selectedBufferSlot.current && isBufferSaleAvailable) {
          WizardFromUpdateBody = {
            ...WizardFromUpdateBody,
            deliveryDateId:
              selectedBufferSlot.current.nextVirtualDeliveryDateId,
            deliveryDateDescription: selectedBufferSlot.current.description,
          };
        } else {
          WizardFromUpdateBody = {
            ...WizardFromUpdateBody,
            deliveryDateId: values.deliveryDateId,
            overrideDeliverySlotId: values.deliverySlotId,
            deliveryDateDescription: values.deliveryDateDescription,
          };
        }
      }
      dispatch(WIZARD_FORM_UPDATE(WizardFromUpdateBody));
      dispatch(WIZARD_CURRENTPAGE_VALIDATE_RESULT({ isValid }));
    }
  );

  const availableDeliverySlotsMapper = (
    deliverySlots: Array<DeliverySlotsIncludeBufferGroupedSummary>
  ): Array<CookbookDeliverySlotGroupedSummary> => {
    const regularDeliverySlots = deliverySlots.flatMap(
      (deliverySlot: DeliverySlotsIncludeBufferGroupedSummary) =>
        deliverySlot.regularDeliverySlots as unknown as DeliverySlotsGroupedSummary
    );

    const bufferDeliverySlots = deliverySlots.flatMap(
      (deliverySlot: DeliverySlotsIncludeBufferGroupedSummary) =>
        deliverySlot.bufferDeliverySlots as unknown as DeliverySlotsGroupedSummary
    );

    if (bufferDeliverySlots.length > 0) {
      isBufferSaleAvailable.current = true;
      bufferSaleDeliverySlots.current = bufferDeliverySlots.flatMap(
        (deliverySlot: DeliverySlotsGroupedSummary) =>
          deliverySlot.deliverySlots as ProductDeliverySlotDto[]
      );
      showBufferSalesCtaDialog();
    }

    return regularDeliverySlots.map((d) => ({
      deliveryDay: d.deliveryDay as unknown as DayOfWeek,
      deliverySlots:
        d.deliverySlots &&
        d.deliverySlots.map((s) => ({
          deliverySlotId: s.deliverySlotId,
          deliveryDay: d.deliveryDay as unknown as DayOfWeek,
          description: s.description,
          availableFrom: s.availableFrom.toString(),
          availableTo: s.availableTo.toString(),
          nextVirtualDeliveryDate: s.nextVirtualDeliveryDate,
          nextVirtualDeliveryDateId: s.nextVirtualDeliveryDateId,
          sortOrder: s.sortOrder,
        })),
    }));
  };

  const convertDateToSlotDay = (date: Date): string => {
    return format(date, `EEEE`);
  };

  const convertDateToSlotTime = (date: Date): string => {
    return format(date, `EEEE 'the' do 'of' MMMM`);
  };

  const showBufferSalesCtaDialog = () => {
    setIsBufferCTAButtonDisplay(true);
  };

  const bufferSalesModalCallback = (status: boolean) => {
    //true: User has selected "Yes please"
    if (status) {
      selectedBufferSlot.current = availableBufferSlot.current;
      setIsBufferCTAButtonDisplay(false);
      setIsBufferSaleSubsectionOpen(true);
    }
  };

  const bufferSalesSubsectionRemoveAction = () => {
    // clear selected buffer slot if subsection is moved
    selectedBufferSlot.current = undefined;
    setIsBufferSaleSubsectionOpen(false);
    setIsBufferCTAButtonDisplay(true);
  };

  const bufferSalesModalToggle = () => {
    setIsBufferSaleModalOpen((open) => !open);
  };

  const bufferSalesModalDisplay = async () => {
    try {
      setIsBufferCTAButtonLoading(true);
      assignBufferSlotAction(
        selectedRegularDeliverySlot.current?.deliverySlotId ?? 0
      );
      await getBufferRecipes();
      setIsBufferSaleModalOpen(true);
    } finally {
      setIsBufferCTAButtonLoading(false);
    }
  };

  const getBufferRecipes = async () => {
    try {
      if (bufferSaleCarouselItems.current.length === 0) {
        const bufferWeek = new Date();
        bufferWeek.setDate(
          // get the next coming sunday's recipe
          bufferWeek.getDate() + ((7 - 1 - bufferWeek.getDay() + 7) % 7) + 1
        );

        const recipes = await getRecipeForBufferSlot(
          selectedSku,
          new Date(bufferWeek)
        );
        const bufferAvailableRecipes = recipes
          // 2 comes from StockAvailability enum in Onion
          // 0 = NoStock
          // 1 = LowStock (unused as of 29/08)
          // 2 = HighStock
          .filter((recipe: RecipeDto) => recipe.stockAvailability === 2)
          .slice(0, selectedNights);

        if (bufferAvailableRecipes) {
          bufferAvailableRecipes.forEach((recipe) => {
            bufferSaleCarouselItems.current.push({
              key: `${recipe.recipeNumber}-${recipe.recipeVersion}`,
              component: RecipeCarouselItem({
                id: recipe.recipeNumber ?? '',
                src: recipe.imageUrl ?? '',
                alt: recipe.subtitle ?? '',
                showShadow: true,
              }),
            });
          });
        }
      }
    } catch (error) {
      navigate('/error', { state: { id: 'delivery-details', error: error } });
    }
  };

  const assignBufferSlotAction = (deliverySlotId: number) => {
    matchDeliverySlotCheck.current = false;
    if (bufferSaleDeliverySlots.current.length === 1) {
      availableBufferSlot.current = bufferSaleDeliverySlots.current[0];
    } else {
      const matchDeliverySlot = bufferSaleDeliverySlots.current.find(
        (deliverySlot: ProductDeliverySlotDto) =>
          deliverySlot.deliverySlotId === deliverySlotId
      );

      if (matchDeliverySlot) {
        availableBufferSlot.current = matchDeliverySlot;
        matchDeliverySlotCheck.current = true;
      } else {
        const farthestDeliverySlot = bufferSaleDeliverySlots.current.reduce(
          (acc, curr) => {
            return acc.nextVirtualDeliveryDate > curr.nextVirtualDeliveryDate
              ? acc
              : curr;
          }
        );
        availableBufferSlot.current = farthestDeliverySlot;
      }
    }
    bufferDeliveryDate.current = convertDateToSlotTime(
      availableBufferSlot.current.nextVirtualDeliveryDate
    );
  };

  const searchAddressOnDebounce = debounce(async (searchInput: string) => {
    try {
      if (searchInput != null && searchInput.length >= 5) {
        setIsSearchingAddress(true);
        let addressSearchResult = new Array<AddressSuggestionDto>();
        addressSearchResult = await searchAddress(searchInput);
        if (!isEmpty(addressSearchResult)) {
          setAddressSuggestions(addressSearchResult);
        }
      } else {
        setAddressSuggestions([]);
      }
    } catch (error) {
      navigate('/error', { state: { id: 'delivery-details', error: error } });
    } finally {
      if (!isFindingDeliverySlots) {
        setIsSearchingAddress(false);
      }
    }
  }, DEBOUNCE_TIME);

  if (!emailAddress) {
    navigate('/error', {
      state: {
        id: 'delivery-details',
        error: new Error('Email address is required.'),
      },
    });
    // return null;
  }

  const addressSelectorOnChange = async (
    item: AddressSuggestionDto | undefined
  ) => {
    try {
      setAvailableDeliverySlots([]);
      setIsBufferSaleSubsectionOpen(false);
      setIsValidAddress(false);
      selectedBufferSlot.current = undefined; //remove selection
      if (item && orderFormData.sku) {
        setIsSearchingAddress(true);
        setValue(DeliveryDetailsInputNames.DeliveryPafId, item.suggestionId);

        const addressSummary = await verifyAddress(item.suggestionId);

        setIsSearchingAddress(false);

        if (!addressSummary.isValid && addressSummary.errorMessage) {
          setError(DeliveryDetailsInputNames.DeliveryAddressId, {
            type: 'address is out of delivery zone',
            message: DeliveryDetailsErrors.DeliveryAddressIsOutSideDeliveryZone,
          });
          setValue(DeliveryDetailsInputNames.DeliveryAddressId, 0);
        } else {
          setIsFindingDeliverySlots(true);
          setValue(DeliveryDetailsInputNames.DeliveryAddress, item.fullAddress);
          setValue(
            DeliveryDetailsInputNames.DeliveryAddressId,
            addressSummary.addressId
          );
          setIsValidAddress(true);
          onInputChange(DeliveryDetailsInputNames.DeliveryAddressId);

          const deliverySlotsResult =
            await getDeliverySlotsForProductAndAddress(
              orderFormData.sku,
              addressSummary.addressId
            );

          setAvailableDeliverySlots(
            availableDeliverySlotsMapper(deliverySlotsResult)
          );
        }
        const updateLeadCommand = new UpdateLeadCommand({
          email: emailAddress,
          addressId: addressSummary.addressId,
        });

        updateLead(updateLeadCommand);
      }
    } catch (error) {
      navigate('/error', { state: { id: 'delivery-details', error: error } });
    }
  };

  const deliverySlotSelectorOnChange = (
    deliverySlotSelectorResult: DeliverySlotSelectorResult
  ): void => {
    setValue(
      DeliveryDetailsInputNames.DeliveryDateId,
      deliverySlotSelectorResult.nextVirtualDeliveryDateId
    );
    setValue(
      DeliveryDetailsInputNames.DeliverySlotId,
      deliverySlotSelectorResult.deliverySlotId
    );

    const deliveryDateDescription = convertDateToSlotTime(
      deliverySlotSelectorResult.nextVirtualDeliveryDate
    );
    setValue(
      DeliveryDetailsInputNames.DeliveryDateDescription,
      deliveryDateDescription
    );
    onInputChange(DeliveryDetailsInputNames.DeliveryDateId);
  };

  /**
   * REQUIRED CALLBACK
   * Validate step/form fields and submit the validation result to redux.
   */
  const validateStep = React.useCallback(async () => {
    if (emailAddress) {
      dispatch(
        UPDATE_DELIVERY_DETAILS_THUNK({
          email: emailAddress,
          getValues,
          trigger,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getValues, trigger]);

  /**
   * REQUIRED EFFECT
   * Respond to external validation requests i.e. from @see OrderForm
   */
  React.useEffect(() => {
    if (pageState === 'VALIDATE') {
      validateStep();
    }
  }, [validateStep, pageState]);

  React.useEffect(() => {
    if (isSlotDropDownOpen && isBufferSaleSubsectionOpen) {
      bufferSalesSubsectionRemoveAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSlotDropDownOpen]);

  //Debounce validation on form input.
  const onDebounce = debounce((inputName: string) => {
    clearErrors(inputName);
    trigger(inputName);
  }, DEBOUNCE_TIME);

  //Triggered on input to any of the three fields.
  const onInputChange = React.useCallback(
    (inputName: string) => {
      clearErrors(inputName);
      onDebounce(inputName);
    },
    [clearErrors, onDebounce]
  );

  return (
    <div className="d-flex justify-content-center flex-column container">
      <h1>Where and when?</h1>
      <div className="row">
        <div className="col-lg-6 col">
          <div className="form-group">
            <DeliveryAddressAutocomplete
              fieldName={DeliveryDetailsInputNames.DeliveryAddressId}
              errorMessage={errorsCallback(
                DeliveryDetailsInputNames.DeliveryAddressId
              )}
              successMessage={DeliveryDetailsSuccessMessage}
              validAddress={isValidAddress}
              isSearching={isSearchingAddress}
              addressSuggestions={addressSuggestions}
              searchAddressCallback={async (searchInput: string) => {
                await searchAddressOnDebounce(searchInput);
              }}
              itemSelectedCallback={async (
                item: AddressSuggestionDto | undefined
              ) => {
                await addressSelectorOnChange(item);
              }}
            />
          </div>
          <div className="form-group">
            <label>Delivery Instructions</label>
            <textarea
              {...register(
                `${DeliveryDetailsInputNames.DeliveryInstructions}`,
                {
                  onChange: () => {
                    onInputChange(
                      DeliveryDetailsInputNames.DeliveryInstructions
                    );
                  },
                }
              )}
              placeholder="e.g. Leave under carport"
              className={classnames('form-control w-100 bg-transparent py-1')}
              maxLength={100}
            />
            <small className="text-muted">
              Instructions must be 100 characters or less.
            </small>
          </div>
          <div className="form-group">
            <label>Delivery Day and Time</label>
            <DeliverySlotSelectorDropDown
              availableDeliverySlots={availableDeliverySlots}
              onChange={(
                deliverySlotSelectorResult: DeliverySlotSelectorResult
              ) => {
                setIsFindingDeliverySlots(false);
                deliverySlotSelectorOnChange(deliverySlotSelectorResult);

                selectedRegularDeliverySlot.current =
                  deliverySlotSelectorResult;
              }}
              errorMessage={errorsCallback(
                DeliveryDetailsInputNames.DeliveryDateId
              )}
              isSlotSelectorDisplay={(isDropDownOpen: boolean) =>
                setIsSlotDropDownOpen(isDropDownOpen)
              }
              isLoading={isFindingDeliverySlots}
            />
          </div>

          {availableDeliverySlots.length > 0 && (
            <div className="d-flex justify-content-center align-items-center px-4">
              <p className="text-center">
                {isBufferSaleSubsectionOpen &&
                !matchDeliverySlotCheck.current &&
                selectedBufferSlot.current ? (
                  <>
                    {"You'll receive your first delivery on "}
                    <b>
                      {convertDateToSlotTime(
                        selectedBufferSlot.current.nextVirtualDeliveryDate
                      )}
                      .
                    </b>
                    {" After that, you'll get weekly deliveries on "}
                    <b>
                      {selectedRegularDeliverySlot.current &&
                        convertDateToSlotDay(
                          selectedRegularDeliverySlot.current
                            .nextVirtualDeliveryDate
                        )}
                      {'.'}
                    </b>
                  </>
                ) : (
                  <>
                    {"You'll receive weekly deliveries from "}
                    <b>
                      {isBufferSaleSubsectionOpen && selectedBufferSlot.current
                        ? convertDateToSlotTime(
                            selectedBufferSlot.current.nextVirtualDeliveryDate
                          )
                        : selectedRegularDeliverySlot.current &&
                          convertDateToSlotTime(
                            selectedRegularDeliverySlot.current
                              .nextVirtualDeliveryDate
                          )}
                    </b>
                  </>
                )}
              </p>
            </div>
          )}

          {isBufferCTAButtonDisplay && (
            <div className="d-flex justify-content-center align-items-center mt-3 px-4">
              <AsyncButton
                type="button"
                className="btn btn-primary"
                isLoading={isBufferCTAButtonLoading}
                onClick={bufferSalesModalDisplay}
              >
                Want a delivery sooner?
              </AsyncButton>
            </div>
          )}
          <div className="form-group">
            <BufferSalesModal
              buttonCallback={bufferSalesModalCallback}
              openModalToggle={bufferSalesModalToggle}
              isOpen={isBufferSaleModalOpen}
              carouselItems={bufferSaleCarouselItems.current}
              requiredSelectionCount={selectedNights}
              deliveryDate={bufferDeliveryDate.current ?? ''}
              carouselResponsiveSlideCount={{
                small: 1.2,
                medium: 1.2,
                large: 2.2,
                extraLarge: 2.2,
              }}
            />
          </div>
        </div>
        <div className="col-lg-6">
          {isBufferSaleSubsectionOpen && (
            <div className="form-group">
              <BufferSalesSubsection
                buttonCallback={bufferSalesSubsectionRemoveAction}
                carouselResponsiveSlideCount={{
                  small: 1.2,
                  medium: 1.8,
                  large: 1.8,
                  extraLarge: 1.8,
                }}
                carouselItems={bufferSaleCarouselItems.current}
                requiredSelectionCount={selectedNights ?? 0}
                deliveryDate={bufferDeliveryDate.current ?? ''}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
