import React, {useEffect} from 'react';
import {
  OrderFormDiscount,
  OrderFormDiscountType,
  Price,
  PromoInputField,
  OrderSummary,
} from '@mfb/cookbook';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import _ from 'lodash';
import {appConfig} from 'config';
import {useErrorMessage, useAppDispatch, useAppSelector} from 'hooks';
import {
  Session,
  wizardFormData,
  PageStates,
  WIZARD_CURRENTPAGE_IDLE,
  WIZARD_CURRENTPAGE_VALIDATE_RESULT,
  trackEcommerceAnalyticsThunk,
  validateCodeAndUpdateSummary,
} from 'features';

//Takes a discount response from the redux store and converts it into a displayable object.
const discountStateToOrderFormDiscountObject = (
  discount: any
): OrderFormDiscount => {
  const code = discount.code;
  const amount = discount.valueOff;
  const type =
    discount.discountType === 2
      ? OrderFormDiscountType.Promotion
      : OrderFormDiscountType.Referral;
  const message: Array<string> = [];
  discount.benefits.forEach((benefit: any) => {
    message.push(benefit.message);
  });
  return {code, amount, type, message};
};

//Takes a voucher response from the redux store and converts it into a displayable object.
const voucherStateToOrderFormDiscountObject = (
  voucher: any
): OrderFormDiscount => {
  const code = voucher.code;
  const amount = voucher.valueOffOrder;
  const type = OrderFormDiscountType.Voucher;
  const message: Array<string> = [];
  return {code, amount, type, message};
};

const INPUT_FIELD_NAME = 'promoFieldInput';

export enum PaymentDetailsMessages {
  PromoFieldRequired = 'Oops, you forgot to enter a code!',
  GenericErrorMessage = 'Something has gone wrong.',
  GenericSuccessMessage = 'Code successfully applied!',
}

const validationSchema = yup.object().shape({
  promoFieldInput: yup
    .string()
    .required(PaymentDetailsMessages.PromoFieldRequired),
});

export interface PaymentDetailsStepProps {
  pageState: PageStates;
  stub?: string;
  pageNumber?: number;
}

export const PaymentDetailsStep: React.FunctionComponent<
  PaymentDetailsStepProps
> = ({pageState}) => {
  const [promoOrReferral, setPromoOrReferral] = React.useState<
    OrderFormDiscount | undefined
  >(undefined);
  const [voucher, setVoucher] = React.useState<OrderFormDiscount | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [codeInput, setCodeInput] = React.useState<string>('');
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [requestPayment, setRequestPayment] = React.useState<boolean>(false);

  const pageData = useAppSelector(wizardFormData);
  const dispatch = useAppDispatch();

  const useFormMethods = useForm({resolver: yupResolver(validationSchema)});

  const {errors} = useFormMethods.formState;
  const errorsCallback = useErrorMessage(errors);

  const {register, trigger, setValue, setError, clearErrors} = {
    ...useFormMethods,
  };

  /**
   * Effect that tracks the component pageInit
   */
  const [pageInit, setPageInit] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      if (pageInit) {
        await dispatch(
          trackEcommerceAnalyticsThunk({
            pageName: appConfig.analytics.pageNames.paymentDetails,
          })
        );
        setPageInit(false);
      }
    })();
  }, [dispatch, pageInit]);

  /**
   * REQUIRED CALLBACK
   * Validate step/form fields and submit the validation result to redux.
   */
  const validateStep = React.useCallback(async () => {
    //Validation on the promo input field does not need to be done here, it is done on the apply button.
    const isValid = !isLoading;
    // Handle Validation + Navigation manually.
    await dispatch(WIZARD_CURRENTPAGE_VALIDATE_RESULT({isValid: false}));
    if (isValid) {
      setRequestPayment(true);
    }
  }, [dispatch, isLoading]);

  /**
   * REQUIRED EFFECT
   * Respond to external validation requests i.e. from @see OrderForm
   */
  React.useEffect(() => {
    if (pageState === 'VALIDATE') {
      validateStep();
    }
  }, [validateStep, pageState]);

  const onApplyClicked = async () => {
    const validInput = await trigger(INPUT_FIELD_NAME);
    setIsLoading(true);

    let code: string = '';
    if (pageData.orderSummaryDiscount?.code) {
      code = pageData.orderSummaryDiscount.code;
    }

    if (codeInput.length > 0) {
      code = codeInput;
    }

    try {
      if (validInput) {
        await dispatch(
          validateCodeAndUpdateSummary({
            code: code,
            orderSummaryDiscount: pageData.orderSummaryDiscount,
            orderSummaryVoucher: pageData.orderSummaryVoucher,
            setError,
            setSuccessMessage,
            clearErrors,
            fieldName: INPUT_FIELD_NAME,
          })
        );
      }
    } finally {
      setIsLoading(false);

      // pageState needs to be set to IDLE,
      // otherwise the Footer button will get stuck on loading
      dispatch(WIZARD_CURRENTPAGE_IDLE());
    }
  };

  //On entering this sceen, check for a default promo code (passed in from query or local (or theoretically this??)) and validate it, and send its info to state.
  useEffect(() => {
    const validateDefaultCode = async () => {
      if (_.get(pageData.defaultDiscountObject, 'code')) {
        setValue(
          'promoFieldInput',
          _.get(pageData.defaultDiscountObject, 'code')
        );
        setIsLoading(true);
        try {
          await dispatch(
            validateCodeAndUpdateSummary({
              code: _.get(pageData.defaultDiscountObject, 'code', ''),
              orderSummaryDiscount: pageData.orderSummaryDiscount,
              orderSummaryVoucher: pageData.orderSummaryVoucher,
              setError,
              clearErrors,
              setSuccessMessage,
              fieldName: INPUT_FIELD_NAME,
            })
          );
        } finally {
          setIsLoading(false);
          dispatch(WIZARD_CURRENTPAGE_IDLE());
        }
      }
    };
    validateDefaultCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Listen to discount and voucher in redux and apply to data format for the display
  useEffect(() => {
    if (pageData.orderSummaryDiscount) {
      setPromoOrReferral(
        discountStateToOrderFormDiscountObject(pageData.orderSummaryDiscount)
      );
    }
  }, [pageData.orderSummaryDiscount]);

  //Listen to discount and voucher in redux and apply to data format for the display
  useEffect(() => {
    if (pageData.orderSummaryVoucher) {
      setVoucher(
        voucherStateToOrderFormDiscountObject(pageData.orderSummaryVoucher)
      );
    }
  }, [pageData.orderSummaryVoucher]);

  if (requestPayment) {
    return <Session />;
  }

  return (
    <div className="d-flex justify-content-center flex-column container-fluid">
      <h1>Have a discount code?</h1>
      <div className="row">
        <div className="col-lg-6 col">
          <PromoInputField
            className="mt-3 mr-lg-5"
            onClickCallback={onApplyClicked}
            errorMessage={errorsCallback(INPUT_FIELD_NAME)}
            successMessage={successMessage}
            register={register}
            isLoading={isLoading}
            onChange={(e) => setCodeInput(e.target.value)}
          />
        </div>
        <div className="col-lg-6 mt-1">
          <OrderSummary
            promoOrReferral={!isLoading ? promoOrReferral : undefined}
            voucher={voucher}
            className=""
            showSummary={true}
            productName={pageData.productName ?? ''}
            price={pageData.price as Price}
            headerLabel="Your Subscription"
          />
        </div>
      </div>
    </div>
  );
};
