import {createApi} from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import {
  CalculateCostModel,
  CalculateCostResponse,
  ApiClientException,
  ICalculateCostModel,
  ValidateCodeResponse,
  ProductGroupResponseOrderForm,
  RecipeDto,
  CreateCartModel,
  CartCreatedResponse,
  RecipeResponse,
} from 'app';
import {ApiClient, retryable} from 'features';

export const orderformApi = createApi({
  reducerPath: 'orderformApi',
  baseQuery: async (apiClientFn: () => Promise<unknown>, _, __) => {
    try {
      const result = await retryable(() => apiClientFn());
      return {
        data: result,
      };
    } catch (error) {
      if (error instanceof ApiClientException) {
        return {error: {status: error.status, data: error.message}};
      }
      if (error instanceof Error) {
        return {error: {status: 500, data: error.message}};
      }
      console.error(error);
      return {error: {status: 500, data: 'Logged to std::error'}};
    }
  },
  endpoints: (builder) => ({
    calculateCost: builder.query<CalculateCostResponse, ICalculateCostModel>({
      query: (model) => () =>
        ApiClient().calculateCost(new CalculateCostModel(model)),
    }),
    validateCode: builder.query<
      ValidateCodeResponse,
      {code: string; cartId: string}
    >({
      query: (model) => () =>
        ApiClient().validateCode(model.code, model.cartId),
    }),
    productGroup: builder.query<Array<ProductGroupResponseOrderForm>, void>({
      query: () => () => ApiClient().productGroup(),
    }),
    recipeCollections: builder.query<
      Array<RecipeDto>,
      {sku: string; weekDateIsoString: string}
    >({
      query:
        ({sku, weekDateIsoString}) =>
        () =>
          ApiClient().getAvailableRecipesForSkuAndWeek(
            sku,
            new Date(weekDateIsoString)
          ),
    }),
    recipeDetails: builder.query<
      RecipeResponse,
      {
        partition: string;
        recipeNumber: string;
        recipeVersion: string;
        weekDateIsoString: string;
      }
    >({
      query:
        ({partition, recipeNumber, recipeVersion, weekDateIsoString}) =>
        () =>
          ApiClient().getRecipeDetails(
            partition,
            recipeNumber,
            recipeVersion,
            new Date(weekDateIsoString)
          ),
    }),
    createCart: builder.mutation<CartCreatedResponse, {sku: string}>({
      query:
        ({sku}) =>
        () =>
          ApiClient().createCart(new CreateCartModel({sku})),
    }),
  }),
});

const toCamlCase = (obj: {} | []): any => {
  if (_.isArray(obj)) {
    return obj.map((x: any) => toCamlCase(x));
  }

  return _.reduce(
    obj,
    (camelCaseObj, value, key) => {
      const convertedDeepValue: any =
        _.isPlainObject(value) || _.isArray(value) ? toCamlCase(value) : value;
      return {...camelCaseObj, [_.camelCase(key)]: convertedDeepValue};
    },
    {}
  );
};

export const {
  useCalculateCostQuery,
  useProductGroupQuery,
  useRecipeCollectionsQuery,
  useLazyCalculateCostQuery,
  useLazyValidateCodeQuery,
  useCreateCartMutation,
  useLazyRecipeDetailsQuery,
} = orderformApi;
