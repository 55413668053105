import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {Outlet, useNavigate} from 'react-router-dom';
import {HeaderFooterLayout} from 'pages';
import {useAppSelector, useAppDispatch, useCalculateCost} from 'hooks';
import styled from 'styled-components';
import {AsyncButton, Colours} from '@mfb/lego';
import {
  wizardFormCustomerExists,
  wizardFormDataSku,
  wizardPageState,
  WIZARD_CURRENTPAGE_VALIDATE,
  WIZARD_PAGE_ROUTE_THUNK,
  WIZARD_STATE_UPDATE,
  Loading,
  OrderFormHeader,
  OrderFormFooter,
  PriceDisplay,
  trackEcommerceAnalyticsThunk,
} from 'features';
import _ from 'lodash';
import {appConfig} from 'config';
import {buildLoginfromCheckoutEvent} from 'utilities';

const StyledForm = styled.form`
  padding: ${({theme}) => `${theme.spacing.xlarge} ${theme.spacing.large}`};
  max-width: ${({theme}) => theme.layout.containerMaxWidths.xl};
  width: 100%;
  @media ${(props) => props.theme.layout.deviceMediaQueries.min.md} {
    padding: ${({theme}) => `${theme.spacing.jumbo} ${theme.spacing.large}`};
  }
`;

const StyledFooterPrice = styled.h5`
  font-weight: 700;
  font-size: ${(props) => props.theme.typography.titleMedium};
  margin-bottom: 0;
`;

export const OrderFormPage = () => {
  const dispatch = useAppDispatch();
  const {currentPageNumber, pages, currentPage} =
    useAppSelector(wizardPageState);
  const methods = useForm();
  const {handleSubmit} = methods;
  const navigate = useNavigate();
  const sku = useAppSelector(wizardFormDataSku);

  const customerExists = useAppSelector(wizardFormCustomerExists);

  /**
   * Notify @see wizardState:CONTINUE listeners to prepare to continue. i.e. @see OrderFormStepManager:Effect
   */
  const onContinue = React.useCallback(() => {
    dispatch(WIZARD_STATE_UPDATE({wizardState: 'CONTINUE'}));
    dispatch(WIZARD_CURRENTPAGE_VALIDATE());
  }, [dispatch]);

  const proceedToAccount = async () => {
    await dispatch(
      trackEcommerceAnalyticsThunk({
        pageName: appConfig.analytics.pageNames.personalDetails,
      })
    );

    if (customerExists.loginUrl) {
      buildLoginfromCheckoutEvent();
      location.replace(customerExists.loginUrl);
    }
  };

  const onCheckpointClick = React.useCallback(
    (pageNumber: number) => {
      dispatch(
        WIZARD_PAGE_ROUTE_THUNK({
          routeType: {direction: 'page', pageNumber},
          navigate: navigate,
        })
      );
    },
    [dispatch, navigate]
  );

  const Header = () => (
    <OrderFormHeader
      isSticky={false}
      breadcrumbsHaveProgressedTo={currentPageNumber - 1}
      breadcrumbsSelected={currentPageNumber}
      breadcrumbsCheckpoints={pages.map((page) => ({
        label: page.pageName,
        disabled: false,
      }))}
      onCheckpointClick={onCheckpointClick}
    />
  );

  const {isSuccess, isFetching, pricing} = useCalculateCost(
    {
      sku: sku.wizardStateSku,
      defaultPromoCode: sku.defaultPromoCode,
    },
    {
      condition: !sku.wizardStateSku,
    }
  );

  const Footer = () => (
    <OrderFormFooter>
      <StyledFooterPrice>
        {!isSuccess || isFetching ? (
          <Loading $size="2" />
        ) : pricing.hasDiscount ? (
          <PriceDisplay
            price={`$${pricing.discountedTotal.toFixed(2)} first week`}
            priceFormatString=""
            showPriceBeforeDiscount={true}
            priceBeforeDiscount={`$${pricing.total.toFixed(2)}`}
            discountColor={Colours.STATES_PROMO_BLUE}
          />
        ) : (
          <PriceDisplay
            price={`$${pricing.total.toFixed(2)} weekly`}
            priceFormatString=""
            showPriceBeforeDiscount={false}
          />
        )}
      </StyledFooterPrice>
      <AsyncButton
        isLoading={_.get(currentPage, 'pageState') !== 'IDLE'}
        className="btn btn-primary"
        onClick={
          customerExists.isCustomer &&
          currentPage?.pageStub === 'personal-details'
            ? proceedToAccount
            : onContinue
        }
      >
        {customerExists.isCustomer &&
        currentPage?.pageStub === 'personal-details'
          ? 'Sign in'
          : 'Continue'}
      </AsyncButton>
    </OrderFormFooter>
  );

  return (
    <FormProvider {...methods}>
      <HeaderFooterLayout header={<Header />} footer={sku ? <Footer /> : ''}>
        <StyledForm
          onSubmit={handleSubmit(() => dispatch(WIZARD_CURRENTPAGE_VALIDATE()))}
        >
          <Outlet />
        </StyledForm>
      </HeaderFooterLayout>
    </FormProvider>
  );
};

export default OrderFormPage;
