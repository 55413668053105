export const useRecipeDate = (week?: Date) => {
  // Why without time? because infinite react loops. That's why!
  const date = week ? new Date(week) : new Date();
  const [dateWithoutTime] = date.toISOString().split('T');

  const originalDate = new Date(dateWithoutTime);

  const recipeWeek = new Date(originalDate);
  if (recipeWeek.getDay() === 0) {
    recipeWeek.setDate(
      recipeWeek.getDate() + ((7 - 1 - recipeWeek.getDay() + 7) % 7) + 1
    );
  } else {
    recipeWeek.setDate(
      recipeWeek.getDate() + ((14 - 1 - recipeWeek.getDay() + 14) % 14) + 1
    );
  }

  return {
    week: originalDate,
    weekIso: originalDate.toISOString(),
    recipeWeek: recipeWeek,
    recipeWeekIso: recipeWeek.toISOString(),
  };
};
