import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PURGE} from 'redux-persist';
import {RootState} from 'app';
import {
  trackEcommerceAnalytics,
  trackEcommerceSuccessAnalytics,
  EcommerceCheckoutEventProps,
  EcommerceSuccessEventProps,
} from 'utilities';
import {DiscountType} from 'features';

export const analyticsSliceName = 'analytics';

interface TrackEcommerceAnalyticsThunkProps {
  pageName: string;
  currentPageNumber?: number;
}
export const trackEcommerceAnalyticsThunk = createAsyncThunk(
  `${analyticsSliceName}/trackEcommerce`,
  async (props: TrackEcommerceAnalyticsThunkProps | undefined, {getState}) => {
    const state = getState() as RootState;
    const trackers = getTrackers(state);
    const pageNumber = state.wizardPage.currentPageNumber + 1;

    let defaultAnalytics: EcommerceCheckoutEventProps = {
      productName: state.wizardForm.productName ?? '',
      productSku: state.wizardForm.sku ?? '',
      productPrice: state.wizardForm.price?.subtotal ?? 0,
      stepName: state.wizardPage.currentPage?.pageName ?? '',
      stepNumber: pageNumber,
      customerStage: state.wizardForm.personalDetailsIsCustomer
        ? 'Existing'
        : 'New',
      userId: state.wizardForm.customerNumber,
      customerEmail: state.wizardForm.personalDetailsEmailAddress,
      customerFirstName: state.wizardForm.personalDetailsFirstName,
      customerLastName: state.wizardForm.personalDetailsLastName,
    };

    if (props) {
      defaultAnalytics = {
        ...defaultAnalytics,
        stepName: props.pageName,
        stepNumber: props.currentPageNumber ?? pageNumber,
      };
    }

    const tracker = trackers.find(
      (ut) => ut.name === defaultAnalytics.stepName
    );
    if (trackerLimitExceeded(tracker)) {
      return {skipped: true, analytic: defaultAnalytics};
    }

    trackEcommerceAnalytics(defaultAnalytics);
    return {skipped: false, analytic: defaultAnalytics};
  }
);

interface TrackEcommerceSuccessAnalyticsThunkProps {
  transactionId: string;
  affiliation?: string;
}
export const trackEcommerceSuccessAnalyticsThunk = createAsyncThunk(
  `${analyticsSliceName}/trackEcommerceSuccess`,
  async (props: TrackEcommerceSuccessAnalyticsThunkProps, {getState}) => {
    const state = getState() as RootState;
    const trackers = getTrackers(state);
    const {wizardForm} = state;

    let defaultAnalytics: EcommerceSuccessEventProps = {
      stepName: 'purchase-success',
      productName: wizardForm.productName ?? '',
      productSku: wizardForm.sku ?? '',
      productPrice: wizardForm.price?.subtotal ?? 0,

      shippingAmount: wizardForm.price?.shipping ?? 0,
      discountAmount: wizardForm.price?.discount ?? 0,
      taxAmount: 0,
      purchaseAmount: wizardForm.price?.total ?? 0,

      discountType:
        wizardForm.orderSummaryDiscount?.discountType === DiscountType.Promo
          ? 'Coupon'
          : wizardForm.orderSummaryDiscount?.discountType === DiscountType.RaF
          ? 'Referral'
          : 'None',
      discountCode: wizardForm.orderSummaryDiscount?.code,

      customerStage: wizardForm.personalDetailsIsCustomer ? 'Existing' : 'New',
      userId: wizardForm.customerNumber,

      transactionId: props.transactionId,
      affiliation: props.affiliation,

      customerEmail: wizardForm.personalDetailsEmailAddress,
      customerFirstName: wizardForm.personalDetailsFirstName,
      customerLastName: wizardForm.personalDetailsLastName,
    };

    const tracker = trackers.find(
      (ut) => ut.name === defaultAnalytics.stepName
    );
    if (trackerLimitExceeded(tracker)) {
      return {skipped: true, analytic: defaultAnalytics};
    }

    trackEcommerceSuccessAnalytics({
      ...defaultAnalytics,
    });
    return {skipped: false, analytic: defaultAnalytics};
  }
);

interface TrackedAnalytic {
  name: string;
  runs?: number;
  limit?: number;
  skipped?: number;
  type: 'ecommerce' | 'purchase';
}
interface AnalyticsState {
  tracking: Array<TrackedAnalytic>;
}

const initialState: AnalyticsState = {
  tracking: [],
};

/**
 * We only want to fire certain analytics once - ever
 * tracking what's been fired to the current session
 * is the only way to protect against this.
 */
const analyticsSlice = createSlice({
  name: analyticsSliceName,
  initialState: initialState,
  reducers: {
    trackOrUpdate: (
      state,
      action: PayloadAction<{skipped: boolean; analytic: TrackedAnalytic}>
    ) => {
      const {skipped, analytic} = action.payload;

      if (!analytic.name) throw new Error('Tracking name is required');

      const tracker = state.tracking.find((ut) => ut.name === analytic.name);

      if (tracker) {
        tracker.name = analytic.name;
        if (!skipped) {
          tracker.runs = (tracker.runs ?? 0) + 1;
        } else {
          tracker.skipped = (tracker.skipped ?? 0) + 1;
        }
        tracker.limit = analytic.limit;
        tracker.type = analytic.type;
      } else {
        const newTracker: TrackedAnalytic = {
          ...analytic,
          runs: skipped ? 0 : 1,
          skipped: skipped ? 1 : 0,
        };
        state.tracking.push(newTracker);
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(trackEcommerceAnalyticsThunk.fulfilled, (state, action) => {
      const {analytic, skipped} = action.payload;
      return analyticsReducer(
        state,
        trackOrUpdate({
          skipped: skipped,
          analytic: {
            name: analytic.stepName ?? '',
            type: 'ecommerce',
          },
        })
      );
    });
    builder.addCase(
      trackEcommerceSuccessAnalyticsThunk.fulfilled,
      (state, action) => {
        const {analytic, skipped} = action.payload;
        return analyticsReducer(
          state,
          trackOrUpdate({
            skipped: skipped,
            analytic: {
              name: analytic.stepName ?? '',
              type: 'purchase',
            },
          })
        );
      }
    );
    builder.addCase(PURGE, () => initialState);
  },
});

export const {trackOrUpdate} = analyticsSlice.actions;
export const analyticsReducer = analyticsSlice.reducer;
export const getTrackers = (state: RootState) => state.analytics.tracking;
export const trackerLimitExceeded = (tracker: TrackedAnalytic | undefined) =>
  tracker && tracker.limit && tracker.runs && tracker.runs >= tracker.limit;
