import React from 'react';
import {useMsal, useMsalAuthentication} from '@azure/msal-react';
import {Navigate, useNavigate} from 'react-router-dom';
import {
  AuthError,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser';
import {Loading} from 'features';
import {loginRequest} from 'config';

type AuthErrorTypes = 'none' | 'user_cancelled' | 'retry' | 'unknown';

/**
 * Takes a `@azure/msal-browser` AuthError and converts it to a meaningful type that we can respond to in our application.
 *
 * Visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes to handle other cases.
 * @param b2cError Error produced from useMsalAuthentication
 * @returns @see AuthErrorType
 */
const useMsalErrorProcessor = (b2cError: AuthError | null): AuthErrorTypes => {
  if (!b2cError) {
    return 'none';
  }

  const errorCodeRegex = /^(?<errorCode>AADB2C\d{5})/;

  if (!errorCodeRegex.test(b2cError.errorMessage)) {
    return 'unknown';
  }

  const {groups = {}} = errorCodeRegex.exec(b2cError.errorMessage)!;
  switch (groups.errorCode) {
    case 'AADB2C90091':
      return 'user_cancelled';
    case 'AADB2C90037':
      return 'retry';
    default:
      return 'unknown';
  }
};

export const Auth = () => {
  const navigate = useNavigate();
  const {inProgress} = useMsal();
  const {result, error} = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );
  const errorType = useMsalErrorProcessor(error);
  const isAuthenticated = Boolean(result);

  // Having issues with auth? Uncomment these effects to figure it out!
  React.useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error, result]);

  React.useEffect(() => {
    (async () => {
      // console.log('Authentication:', isAuthenticated);
      // console.log('Progress:', inProgress);

      if (isAuthenticated && inProgress === InteractionStatus.None) {
        navigate('/process', {replace: true});
      }
    })();
  }, [isAuthenticated, inProgress, navigate]);

  if (!isAuthenticated && inProgress === InteractionStatus.None) {
    switch (errorType) {
      case 'user_cancelled':
        return <Navigate replace to={'/'} />;
      case 'retry':
        return <Navigate replace to={'/session'} />;
      default:
        return (
          <Navigate
            replace
            to={'/error'}
            state={{id: 'auth-redirect', error: error?.errorMessage}}
          />
        );
    }
  } else {
    return <Loading />;
  }
};
