import { ErrorResponse } from 'app';
import retry, { OperationOptions } from 'retry';

const networkErrorMsgs = new Set([
  'Failed to fetch', // Chrome
  'NetworkError when attempting to fetch resource.', // Firefox
  'The Internet connection appears to be offline.', // Safari
  'Network request failed', // `cross-fetch`
]);

export class AbortError extends Error {
  readonly name: 'AbortError';
  readonly originalError: Error;

  constructor(message: string | Error) {
    super();

    if (message instanceof Error) {
      this.originalError = message;
      ({ message } = message);
    } else {
      this.originalError = new Error(message);
      this.originalError.stack = this.stack;
    }

    this.name = 'AbortError';
    this.message = message;
  }
}

export interface FailedAttemptError extends Error {
  attemptNumber: number;
  retriesLeft: number;
}

export interface Options extends OperationOptions {
  readonly onFailedAttempt?: (
    error: FailedAttemptError
  ) => void | Promise<void>;
  readonly signal?: AbortSignal;
}

const decorateErrorWithCounts = (
  error: FailedAttemptError,
  attemptNumber: number,
  options: Options
) => {
  const retriesLeft = (options.retries ?? 3) - (attemptNumber - 1);

  error.attemptNumber = attemptNumber;
  error.retriesLeft = retriesLeft;
  return error;
};

const isNetworkError = (errorMessage: any) =>
  networkErrorMsgs.has(errorMessage);

const getDOMException = (errorMessage: any) =>
  globalThis.DOMException === undefined
    ? new Error(errorMessage)
    : new DOMException(errorMessage);

function instanceOfFailedAttemptError(
  object: any
): object is FailedAttemptError {
  return true;
}

export async function retryRequest<T>(
  input: (attemptCount: number) => PromiseLike<T> | T,
  options: Options
): Promise<T> {
  return new Promise((resolve, reject) => {
    options = {
      onFailedAttempt() {},
      retries: options.retries ?? 3,
      ...options,
    };

    const operation = retry.operation(options);

    operation.attempt(async (attemptNumber) => {
      try {
        resolve(await input(attemptNumber));
      } catch (error) {
        if (error instanceof ErrorResponse) {
          reject(error);
          return;
        }

        if (error instanceof AbortError) {
          operation.stop();
          reject(error.originalError);
        } else if (
          error instanceof TypeError &&
          !isNetworkError(error.message)
        ) {
          operation.stop();
          reject(error);
        }

        if (instanceOfFailedAttemptError(error)) {
          decorateErrorWithCounts(error, attemptNumber, options);

          try {
            await options.onFailedAttempt!(error);
          } catch (error) {
            reject(error);
            return;
          }

          if (!operation.retry(error)) {
            reject(operation.mainError());
          }
        }
      }
    });

    if (options.signal && !options.signal.aborted) {
      options.signal.addEventListener(
        'abort',
        () => {
          operation.stop();
          const reason =
            options.signal?.reason === undefined
              ? getDOMException('The operation was aborted.')
              : options.signal.reason;
          reject(reason instanceof Error ? reason : getDOMException(reason));
        },
        {
          once: true,
        }
      );
    }
  });
}

export default retryRequest;
