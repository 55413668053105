import {combineReducers} from 'redux';
import {
  wizardFormReducer,
  wizardPageReducer,
  paymentDetailsReducer,
  orderformApi,
  analyticsSliceName,
  analyticsReducer,
} from 'features';

export const rootReducer = combineReducers({
  wizardForm: wizardFormReducer,
  wizardPage: wizardPageReducer,
  paymentDetails: paymentDetailsReducer,
  [analyticsSliceName]: analyticsReducer,
  [orderformApi.reducerPath]: orderformApi.reducer,
});
