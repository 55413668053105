import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app';
import {Discount, Voucher, validateCodeAndUpdateSummary} from 'features';
import {PURGE} from 'redux-persist';
import {DefaultDiscountObject} from 'hooks';

export interface WizardFormState {
  customerNumber?: string;
  personalDetailsFirstName?: string;
  personalDetailsLastName?: string;
  personalDetailsMobileNumber?: string;
  personalDetailsEmailAddress?: string;
  personalDetailsHasLogin?: boolean;
  personalDetailsIsCustomer?: boolean;
  personalDetailsLoginUrl?: string;
  personalDetailsMarketingSmsOptIn?: boolean;
  sku?: string;
  productName?: string;
  nights?: number;
  cartId?: string;
  defaultDiscountObject?: DefaultDiscountObject;
  orderSummaryCodeValidationError?: string;
  orderSummaryDiscount?: Discount;
  orderSummaryVoucher?: Voucher;
  price?: {
    subtotal?: number;
    discount?: number;
    voucher?: number;
    total?: number;
    shipping?: number;
  };
  deliveryAddress?: string;
  deliveryPafId?: number;
  deliveryAddressId?: number;
  overrideDeliverySlotId?: number;
  deliveryInstructions?: string;
  deliveryDateId?: number;
  deliveryDateDescription?: string;
}

const initialState: WizardFormState = {
  personalDetailsFirstName: '',
  personalDetailsLastName: '',
  personalDetailsMobileNumber: '',
  personalDetailsEmailAddress: '',
  personalDetailsHasLogin: false,
  personalDetailsIsCustomer: false,
  personalDetailsLoginUrl: '/',
  personalDetailsMarketingSmsOptIn: false,
  deliveryAddress: '',
  deliveryPafId: 0,
  deliveryAddressId: 0,
  overrideDeliverySlotId: 0,
  deliveryInstructions: '',
  deliveryDateId: 0,
  deliveryDateDescription: '',
};

export const wizardFormSlice = createSlice({
  name: 'wizardForm',
  initialState: initialState,
  reducers: {
    WIZARD_FORM_UPDATE: (state, action: PayloadAction<WizardFormState>) => {
      const formData = action.payload ?? {};
      return {
        ...state,
        ...formData,
      };
    },
    WIZARD_FORM_RESET: () => void initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(validateCodeAndUpdateSummary.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(PURGE, () => void initialState);
  },
});

export const getDataforCurrentPage = (obj: any, datakey: string) => {
  for (const [key, value] of Object.entries(obj)) {
    if (key === datakey) {
      return value;
    }
  }
};

export const {WIZARD_FORM_UPDATE, WIZARD_FORM_RESET} = wizardFormSlice.actions;
export const wizardFormData = (state: RootState) => state.wizardForm;
export const wizardFormPersonalDetails = (state: RootState) => ({
  emailAddress: state.wizardForm.personalDetailsEmailAddress,
  hasLogin: state.wizardForm.personalDetailsHasLogin,
});
export const wizardFormCustomerExists = (state: RootState) => ({
  isCustomer: state.wizardForm.personalDetailsIsCustomer,
  loginUrl: state.wizardForm.personalDetailsLoginUrl,
});
export const wizardFormDataTotalPrice = (state: RootState) =>
  state.wizardForm.price;
export const wizardFormDataSku = (state: RootState) => ({
  wizardStateSku: state.wizardForm.sku,
  defaultPromoCode: state.wizardForm.defaultDiscountObject,
});
export const wizardFormProductInfo = (state: RootState) => ({
  productSku: state.wizardForm.sku ?? '',
  productName: state.wizardForm.productName ?? '',
  productPrice: state.wizardForm.price?.subtotal?.toFixed(2) ?? '',
});
export const wizardFormDeliveryDetails = (state: RootState) => ({
  deliveryInstructions: state.wizardForm.deliveryInstructions ?? '',
});
export const wizardFormReducer = wizardFormSlice.reducer;
