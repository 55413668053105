import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store, persistor} from 'app';
import reportWebVitals from './reportWebVitals';
import OrderForm from './orderForm';

import './index.scss';
import {basename, msalSignInInstance, msalSignUpInstance} from 'config';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {LegoBaseTheme} from '@mfb/lego';
import _ from 'lodash';
import {PersistGate} from 'redux-persist/integration/react';
import {PersistLoading} from 'features';

import {loadGtmTag, loadVwoTag, loadSentryConfig} from 'utilities';

const OrderFormTheme = _.chain(LegoBaseTheme)
  .set('layout.containerMaxWidths.xl', '1370px')
  .value();

/**
 * Load up Analytics before page mount
 */
loadSentryConfig();
loadGtmTag();
loadVwoTag();

const container = document.getElementById('root')!;
const root = createRoot(container);
window.appVersion = process.env.REACT_APP_NUSPEC_VERSION ?? 'unknown';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={OrderFormTheme}>
        {/* change to persistLoading as loading can't be outside of browserRouter */}
        <PersistGate loading={<PersistLoading />} persistor={persistor}>
          <BrowserRouter basename={basename}>
            <OrderForm
              msalSignUpInstance={msalSignUpInstance}
              msalSignInInstance={msalSignInInstance}
            />
          </BrowserRouter>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// Iff you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
