import React, {PropsWithChildren} from 'react';
import {InteractionType, RedirectRequest} from '@azure/msal-browser';
import {MsalAuthenticationTemplate} from '@azure/msal-react';
import {useAppSelector, useAppDispatch} from 'hooks';
import {appConfig, loginRequest} from 'config';
import {
  Loading,
  Error,
  trackEcommerceAnalyticsThunk,
  wizardFormPersonalDetails,
} from 'features';

export interface GuardedRouteProps extends PropsWithChildren {}

const MsalLoading = () => <Loading />;

export const GuardedRoute = ({children}: GuardedRouteProps) => {
  const wizardState = useAppSelector(wizardFormPersonalDetails);
  const dispatch = useAppDispatch();
  const redirectRequest: RedirectRequest = {
    ...loginRequest,
    loginHint: wizardState.emailAddress,
    onRedirectNavigate: () => {
      // This is an intemediary state + so numbers are interesting
      dispatch(
        trackEcommerceAnalyticsThunk({
          pageName: appConfig.analytics.pageNames.authRedirect,
          currentPageNumber: appConfig.analytics.pageNumbers.authRedirect,
        })
      );
    },
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={Error}
      loadingComponent={MsalLoading}
      authenticationRequest={redirectRequest}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};
