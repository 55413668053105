import {
  ValidateCodeResponse,
  CalculateCostModel,
  CalculateCostResponse,
} from 'app';
import {ApiClient, retryable} from 'features';

export const validateCode = async (
  code: string,
  cartId: string
): Promise<ValidateCodeResponse> => {
  const orderSummaryApiClient = ApiClient();
  return retryable(() => orderSummaryApiClient.validateCode(code, cartId));
};

export const calculateCost = async (
  updateSummaryRequest: CalculateCostModel
): Promise<CalculateCostResponse> => {
  const orderSummaryApiClient = ApiClient();
  return retryable(
    () => {
      return orderSummaryApiClient.calculateCost(updateSummaryRequest);
    },
    {retries: 22, factor: 1}
  );
};
