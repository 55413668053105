import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  CalculateCostModel,
  CalculateCostResponse,
  PromoDto,
  ReferAFriendDto,
  RootState,
} from 'app';
import _ from 'lodash';
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';
import {
  calculateCost,
  validateCode,
  WIZARD_FORM_UPDATE,
  wizardFormReducer,
  AddedProduct,
  Benefit,
  Discount,
  Voucher,
} from 'features';
import {PaymentDetailsMessages} from 'pages';

export const validateCodeAndUpdateSummary = createAsyncThunk(
  'orderSummary/validateCodeAndUpdateSummary',
  async (
    {
      code,
      orderSummaryDiscount,
      orderSummaryVoucher,
      setError,
      clearErrors,
      setSuccessMessage,
      fieldName,
    }: {
      code: string;
      orderSummaryDiscount?: Discount;
      orderSummaryVoucher?: Voucher;
      setError: UseFormSetError<FieldValues>;
      clearErrors: UseFormClearErrors<FieldValues>;
      setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
      fieldName: string;
    },
    {getState}
  ) => {
    let state = (getState() as RootState).wizardForm;

    const cartId = state.cartId ?? '';

    try {
      const codeValidationResponse = await validateCode(code, cartId);

      if (
        codeValidationResponse.validationErrors &&
        codeValidationResponse.validationErrors.length
      ) {
        //Errors were found.
        setError(
          fieldName,
          {type: 'custom', message: codeValidationResponse.validationErrors[0]},
          {shouldFocus: true}
        );
        setSuccessMessage('');
        return state;
      }

      const addressId = state.deliveryAddressId;

      const discount =
        codeValidationResponse.promo ||
        codeValidationResponse.raf ||
        orderSummaryDiscount;
      const voucher = codeValidationResponse.voucher || orderSummaryVoucher;

      const updateSummaryRequest = new CalculateCostModel({
        cartId,
        addressId,
        discountCode: discount ? discount.code : undefined,
        discountType: discount ? discount.discountType : undefined,
        voucherCode: voucher ? voucher.code : undefined,
      });

      const costCalculation = await calculateCost(updateSummaryRequest);

      const responseDiscount =
        codeValidationResponse.promo || codeValidationResponse.raf;

      if (responseDiscount) {
        // Adding discount to state.
        const stateDiscountObject: Discount = createStateDiscountObject(
          responseDiscount,
          costCalculation
        );
        state = wizardFormReducer(
          state,
          WIZARD_FORM_UPDATE({
            defaultDiscountObject: {
              code: stateDiscountObject.code,
              type: stateDiscountObject.discountType,
            },
            orderSummaryDiscount: stateDiscountObject,
          })
        );
      } else if (codeValidationResponse.voucher && costCalculation.voucher) {
        //Adding voucher to state.
        const stateVoucherObject: Voucher = {
          code: costCalculation.voucher.code ?? '',
          valueOffOrder: costCalculation.voucher.valueOff,
          voucherId: codeValidationResponse.voucher.voucherId,
          balance: costCalculation.voucher.balance,
        };
        state = wizardFormReducer(
          state,
          WIZARD_FORM_UPDATE({orderSummaryVoucher: stateVoucherObject})
        );
      }
      //Adding the calculate cost data to state.
      state = wizardFormReducer(
        state,
        WIZARD_FORM_UPDATE({
          price: {
            subtotal: costCalculation.subtotal,
            discount: costCalculation.discount?.valueOff,
            voucher: costCalculation.voucher?.valueOff,
            total: costCalculation.total,
            shipping: costCalculation.shippingTotal,
          },
        })
      );
      clearErrors(fieldName);
      setSuccessMessage(PaymentDetailsMessages.GenericSuccessMessage);
      return state;
    } catch {
      setError(
        fieldName,
        {type: 'custom', message: PaymentDetailsMessages.GenericErrorMessage},
        {shouldFocus: true}
      );
      setSuccessMessage('');
      return state;
    }
  }
);

const createStateDiscountObject = (
  discount: PromoDto | ReferAFriendDto,
  costCalculation: CalculateCostResponse
): Discount => {
  return {
    discountType: discount.discountType,
    code: discount.code ?? '',
    message: discount.message ?? '',
    benefits:
      _.get(discount, 'benefits', new Array<Benefit>()).map(
        (b: any, index: any): Benefit => {
          return {
            week: index + 1,
            message: b.message,
            amount: b.amount,
            benefitType: b.benefitType,
            consumableFrom: b.consumableFrom.toISOString(),
          };
        }
      ) ?? [],
    totalDiscount: costCalculation.totalDiscount ?? 0,
    addedProducts: _.get(
      discount,
      'benefits[0].addProductBenefits',
      new Array<AddedProduct>()
    ).map(
      (apb: any): AddedProduct => ({
        name: apb.product.name,
        retailPrice: apb.product.totalPrice,
        overridePrice: apb.overridePrice,
        isFree: apb.overridePrice === 0,
      })
    ),

    valueOff: costCalculation.discount?.valueOff ?? 0,
    promoGroupId: _.get(discount, 'promoGroupId', undefined),
  };
};
