import produce from 'immer';

export interface EcommerceBaseEventType<EcommerceType> {
  event: string;
  customerStage: string;
  userId: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?:string;
  eventDetails: {
    category: string;
    action: string;
    label: string;
  };
  ecommerce: EcommerceType;
}

export interface EcommerceBaseProductType {
  name: string;
  id: string;
  price: number;
  brand: string;
  category: string;
  quantity: number;
  dimension6: string;
  dimension13: string;
}

export interface EcommerceCheckoutType {
  currencyCode: string;
  checkout: {
    actionField: {
      step: number;
      action: string;
    };
    products: Array<EcommerceBaseProductType>;
  };
}

export interface EcommercePurchaseType {
  currencyCode: string;
  purchase: {
    actionField: {
      id: string;
      affiliation: string;
      revenue: number;
      tax: number;
      shipping: number;
      coupon: string;
    };
    products: Array<EcommerceBaseProductType>;
  };
}

export interface EcommerceCheckoutEventProps {
  userId?: string;
  stepNumber?: number;
  stepName?: string;

  productSku: string;
  productName: string;
  productPrice: number;

  productBrand?: 'My Food Bag' | 'Bargain Box';
  productCategory?: string;

  customerStage?: 'New' | 'Existing';
  deliveryFrequency?: 'Weekly' | 'Fortnightly';
  orderFormVersion?: 'v4';

  customerEmail?: string;
  customerFirstName?: string;
  customerLastName? :string;
}

const buildEcommerceEvent = (
  props: EcommerceCheckoutEventProps
): EcommerceBaseEventType<EcommerceCheckoutType> => {
  const initialState: EcommerceBaseEventType<EcommerceCheckoutType> = {
    event: 'ecommerceCheckout',
    customerStage: '{{CUSTOMER-STAGE}}',
    userId: '{{USER-ID}}',
    eventDetails: {
      category: 'ecommerce - v4',
      action: 'checkout {{STEP-NUM}}',
      label: 'sku selector',
    },
    ecommerce: {
      currencyCode: 'NZD',
      checkout: {
        actionField: {
          step: 0,
          action: 'checkout',
        },
        products: [
          {
            name: '{{PRODUCT-NAME}}',
            id: '{{PRODUCT-SKU}}',
            price: 0,
            brand: '{{PRODUCT-BRAND}}',
            category: '{{CATEGORY}}',
            quantity: 1,
            dimension6: '{{DELIVERY-FREQUENCY}}',
            dimension13: '{{ORDER-FORM-VERSION}}',
          },
        ],
      },
    },
  };

  return produce(initialState, (draft) => {
    draft.customerStage = props.customerStage || 'New';
    draft.userId = props.userId ?? '';

    draft.eventDetails.action = `checkout ${props.stepNumber}`;
    draft.eventDetails.label = props.stepName ?? '';

    draft.ecommerce.checkout.actionField.step = props.stepNumber ?? -1;

    const [productSku] = draft.ecommerce.checkout.products;

    if (productSku) {
      productSku.id = props.productSku;
      productSku.name = props.productName;
      productSku.price = props.productPrice;
      productSku.brand = props.productBrand ?? 'My Food Bag';
      productSku.category = props.productCategory ?? 'Bags';
      productSku.dimension6 = props.deliveryFrequency ?? 'Weekly';
      productSku.dimension13 = props.orderFormVersion ?? 'v4';
    }

    if(props.customerEmail)
    {
      draft.customerEmail = props.customerEmail
    }
    if(props.customerFirstName)
    {
      draft.customerFirstName = props.customerFirstName
    }
    if(props.customerLastName)
    {
      draft.customerLastName = props.customerLastName
    }
  });
};

export interface EcommerceSuccessEventProps
  extends EcommerceCheckoutEventProps {
  transactionId: string;

  purchaseAmount: number;
  taxAmount: number;
  shippingAmount: number;
  discountAmount?: number;

  discountCode?: string;
  discountType: 'Referral' | 'Coupon' | 'None';

  affiliation?: string;
}
const buildEcommerceSuccessEvent = (
  props: EcommerceSuccessEventProps
): EcommerceBaseEventType<EcommercePurchaseType> => {
  const initialState: EcommerceBaseEventType<EcommercePurchaseType> = {
    event: 'ecommercePurchase',
    customerStage: '{{CUSTOMER-STAGE}}',
    userId: '{{USER-ID}}',
    eventDetails: {
      category: 'ecommerce - v4',
      action: 'purchase',
      label: 'Success',
    },
    ecommerce: {
      currencyCode: 'NZD',
      purchase: {
        actionField: {
          id: '{{TRANSACTION-ID}}',
          affiliation: '{{AFFILIATION}}',
          revenue: 0,
          tax: 0,
          shipping: 0,
          coupon:
            'coupon:{{PURCHASE-COUPON-CODE}}|referral:{{PURCHASE-REFERRAL-CODE}}',
        },
        products: [],
      },
    },
  };

  return produce(initialState, (draft) => {
    draft.customerStage = props.customerStage || 'New';

    draft.userId = props.userId ?? '';
    draft.ecommerce.purchase.actionField.affiliation = props.affiliation ?? '';

    draft.ecommerce.purchase.actionField.id = props.transactionId;
    draft.ecommerce.purchase.actionField.revenue = props.purchaseAmount;
    draft.ecommerce.purchase.actionField.tax = props.taxAmount;
    draft.ecommerce.purchase.actionField.shipping = props.shippingAmount;

    const discountCode =
      props.discountType === 'Coupon'
        ? `coupon:${props.discountCode}`
        : props.discountType === 'Referral'
        ? `referral:${props.discountCode}`
        : '';

    draft.ecommerce.purchase.actionField.coupon = discountCode;

    draft.ecommerce.purchase.products.push({
      id: props.productSku,
      name: props.productName,
      price: props.productPrice,
      brand: props.productBrand ?? 'My Food Bag',
      category: props.productCategory ?? 'Bags',
      dimension6: props.deliveryFrequency ?? 'Weekly',
      dimension13: props.orderFormVersion ?? 'v4',
      quantity: 1,
    });

    if (props.discountType !== 'None') {
      draft.ecommerce.purchase.products.push({
        id: discountCode,
        name: props.discountType,
        price: props.discountAmount ?? 0,
        brand: 'Coupon',
        category: 'Discount',
        dimension6: props.deliveryFrequency ?? 'Weekly',
        dimension13: props.orderFormVersion ?? 'v4',
        quantity: 1,
      });
    }

    if(props.customerEmail)
    {
      draft.customerEmail = props.customerEmail
    }
    if(props.customerFirstName)
    {
      draft.customerFirstName = props.customerFirstName
    }
    if(props.customerLastName)
    {
      draft.customerLastName = props.customerLastName
    }
  });
};

export {buildEcommerceEvent, buildEcommerceSuccessEvent};
