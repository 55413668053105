import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeader = styled.header``;

const StyledMain = styled.main`
  min-height: 100px;
  height: 100%;
  overflow-x: hidden;
  padding-left: calc(100vw - 100%);
`;

const StyledFooter = styled.footer``;

const StyledMainScrollFixWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: calc(-1 * (100vw - 100%));
  height: 100%;
`;

export interface HeaderFooterLayoutProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const HeaderFooterLayout = ({
  header,
  footer,
  children,
}: React.PropsWithChildren<HeaderFooterLayoutProps>) => {
  return (
    <StyledContainer>
      <StyledHeader>{header}</StyledHeader>
      <StyledMain>
        <StyledMainScrollFixWrapper>{children}</StyledMainScrollFixWrapper>
      </StyledMain>
      <StyledFooter>{footer}</StyledFooter>
    </StyledContainer>
  );
};

export {HeaderFooterLayout};
