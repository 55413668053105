import {memoize} from 'lodash';
import {AddressSuggestionDto, ValidateAddressResponseDto} from 'app';
import {ApiClient} from 'features';

export const searchAddress = memoize(
  async (query: string): Promise<Array<AddressSuggestionDto>> => {
    const addressClient = ApiClient();
    return addressClient.addressSearch(query);
  }
);

export const verifyAddress = async (
  addressId: number
): Promise<ValidateAddressResponseDto> => {
  const addressClient = ApiClient();
  return addressClient.addressValidate(addressId);
};
