import _ from 'lodash';
import {
  ProductGroupResponseOrderForm,
  ProductSummaryResponseOrderForm,
} from 'app';
import {useProductGroupQuery} from 'features';

interface UseProductOptions {
  condition?: boolean;
}

export interface Product {
  name: string;
  itemNumber: string;
  nights: number;
  people: number;
  totalPrice: number;
  deliveryPrice: number | undefined;
  pricePerPlate: number | undefined;
}

export interface ProductGroup {
  productGroupName: string;
  blurb: string;
  products: {
    sku: string;
    itemNumber: string;
    night: number;
    serves: number;
  }[];
}

export interface ProductCollectionInfo {
  productsCollection: Array<Product>;
  productGroups: Array<ProductGroup>;
}

const transformResponse = (
  response: Array<ProductGroupResponseOrderForm>
): ProductCollectionInfo => {
  const productGroups = _.chain(response)
    .filter((item) => item.shortDisplayDescription !== undefined)
    .groupBy((item) => item.shortDisplayDescription)
    .map((item, groupKey) => {
      return {
        productGroupName: groupKey,
        blurb: item[0].blurb as string,
        products: _.chain(item)
          .map((item) => item.productOptions)
          .flattenDeep()
          .value() as Array<ProductSummaryResponseOrderForm>,
        sortOrder: item[0].sortOrder,
      };
    })
    .sortBy((item) => item.sortOrder)
    .value();

  const productGroupForStateService = productGroups.map((item) => {
    return {
      productGroupName: item.productGroupName,
      blurb: item.blurb,
      products: item.products.map((item) => {
        return {
          sku: item.sku!,
          itemNumber: item.sku!,
          night: item.nights,
          serves: item.people,
        };
      }),
    };
  });

  const productCollection = productGroups
    .flatMap((product) => product.products)
    .map((productItem) => {
      return {
        name: productItem.name!,
        itemNumber: productItem.sku!,
        nights: productItem.nights,
        people: productItem.people,
        totalPrice: productItem.totalPrice,
        deliveryPrice: productItem.deliveryPrice,
        pricePerPlate: productItem.pricePerPlate,
      };
    });

  const combineData = {
    productsCollection: productCollection,
    productGroups: productGroupForStateService,
  };

  return combineData;
};

export const useProducts = (options?: UseProductOptions) => {
  let productCollectionInfo: ProductCollectionInfo = {
    productsCollection: [],
    productGroups: [],
  };
  const productGroupQuery = useProductGroupQuery(undefined, {
    skip: Boolean(options?.condition),
  });

  if (productGroupQuery.isSuccess && productGroupQuery.data) {
    productCollectionInfo = transformResponse(productGroupQuery.data);
  }

  return {...productGroupQuery, ...productCollectionInfo};
};
