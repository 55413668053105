import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import {
  Auth,
  Failure,
  Loading,
  Login,
  NotFound,
  PaymentRedirect,
  Restart,
  Success,
  Session,
  Error,
} from 'features';
import {OrderFormPage, OrderFormStepManager} from 'pages';
import {RoutedComponent, GuardedRoute} from 'routes';

export const Routing = () => {
  /**
   * Cache and define a default state for the step manager.
   */
  const CachedOrderFormStepManager = React.useMemo(
    () => <OrderFormStepManager stepStub="create-bag" />,
    []
  );
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/**
         * Be careful updating /protected: Make sure to sync changes
         * to Azure B2C Html + User Flows!!
         */}
        <Route path="/session" element={<Session />} />
        <Route path="/protected" element={<Auth />} />
        <Route path="/" element={<OrderFormPage />}>
          <Route
            index
            element={<RoutedComponent component={CachedOrderFormStepManager} />}
          />
          <Route
            path=":stepStub"
            element={<RoutedComponent component={CachedOrderFormStepManager} />}
          />
        </Route>
        <Route
          path="/process"
          element={
            <GuardedRoute>
              <PaymentRedirect replace />
            </GuardedRoute>
          }
        />
        <Route path="/error" element={<Error />} />
        <Route path="/failure" element={<Failure />} />
        <Route
          path="/success"
          element={
            <GuardedRoute>
              <Success />
            </GuardedRoute>
          }
        />
        <Route path="/restart" element={<Restart />} />
        <Route path="/signout" element={<Restart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
