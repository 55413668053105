import React from 'react';
import {MsalProvider} from '@azure/msal-react';
import {IPublicClientApplication} from '@azure/msal-browser';
import {Routing} from 'routes';
import {useAppSelector, useRouterTracking} from 'hooks';
import {wizardFormPersonalDetails} from 'features';

export type AppProps = {
  msalSignUpInstance: IPublicClientApplication;
  msalSignInInstance: IPublicClientApplication;
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. First initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const OrderForm = ({msalSignUpInstance, msalSignInInstance}: AppProps) => {
  useRouterTracking();
  const wizardState = useAppSelector(wizardFormPersonalDetails);
  const instance = wizardState.hasLogin
    ? msalSignInInstance
    : msalSignUpInstance;

  return (
    <MsalProvider instance={instance}>
      <Routing />
    </MsalProvider>
  );
};

export default OrderForm;
