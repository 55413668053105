import {DeliverySlotsIncludeBufferGroupedSummary, RecipeDto} from 'app';
import {ApiClient, retryable} from 'features';

export const getDeliverySlotsForProductAndAddress = async (
  sku: string,
  addressId: number
): Promise<Array<DeliverySlotsIncludeBufferGroupedSummary>> => {
  const deliverySlotClient = ApiClient();
  return retryable(() =>
    deliverySlotClient.getDeliverySlotsForProductAndAddress(sku, addressId)
  );
};

export const getRecipeForBufferSlot = async (
  sku: string,
  week: Date
): Promise<RecipeDto[]> => {
  const deliverySlotClient = ApiClient();
  return retryable(() =>
    deliverySlotClient.getAvailableRecipesForSkuAndWeek(sku, week)
  );
};
